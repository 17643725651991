import { PIPE_TYPE } from 'imask';

type PipeKeys = keyof typeof PIPE_TYPE;
type PipeType = typeof PIPE_TYPE[PipeKeys];

const curryPipeTypeArgs = (pipeType: PipeType) => [PIPE_TYPE.TYPED, pipeType];
const unmaskedTypes = curryPipeTypeArgs(PIPE_TYPE.UNMASKED);
const maskedTypes = curryPipeTypeArgs(PIPE_TYPE.MASKED);

export { curryPipeTypeArgs, unmaskedTypes, maskedTypes };
