import React from 'react';
import * as Styled from 'components/loader/styled';

const Loader = () => (
    <Styled.Loader>
        <Styled.CircleSVG viewBox="0 0 120 120" xmlns="http://www.w3.org/2000/svg">
            <Styled.Circle cx="60" cy="60" r="50" />
        </Styled.CircleSVG>
    </Styled.Loader>
);

export default Loader;
