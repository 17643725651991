import { ReactNode, useEffect, useState } from 'react';
import * as Dialog from 'components/dialog';
import { FieldPath } from 'react-hook-form';
import { UseFormTextInput } from 'components/form/UseFormTextInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faSpinnerThird, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { useMutation } from '@tanstack/react-query';
import { useCurrentAccount } from 'customHooks/useCurrentAccount';
import { EventType } from 'types/domain';
import { message } from 'antd';
import { useHistory } from 'react-router-dom';
import { AlertNotification } from 'components/alert-notification';
import { RenderOrNull } from 'components/util';
import { ReactComponent as Form1 } from 'assets/js/components/modals/campaign-type/icons/Form1.svg';
import { ReactComponent as Form2 } from 'assets/js/components/modals/campaign-type/icons/Form2.svg';
import { ReactComponent as Form3 } from 'assets/js/components/modals/campaign-type/icons/Form3.svg';
import { ReactComponent as Form4 } from 'assets/js/components/modals/campaign-type/icons/Form4.svg';
import { ReactComponent as Event1 } from 'assets/js/components/modals/campaign-type/icons/Event1.svg';
import { ReactComponent as Event3 } from 'assets/js/components/modals/campaign-type/icons/Event3.svg';
import { ReactComponent as Event4 } from 'assets/js/components/modals/campaign-type/icons/Event4.svg';
import { ReactComponent as Page1 } from 'assets/js/components/modals/campaign-type/icons/Page1.svg';
import { ReactComponent as Page3 } from 'assets/js/components/modals/campaign-type/icons/Page3.svg';
import { ReactComponent as Page4 } from 'assets/js/components/modals/campaign-type/icons/Page4.svg';
import { ReactComponent as AuctionIcon } from 'images/auction-icon.svg';
import { ReactComponent as NewSticker } from 'images/new-sticker.svg';
import { CAMPAIGN_TYPE_LINK } from 'constants/links';
import { createCampaign } from 'api/campaigns';
import { GivebutterFormProvider } from 'components/form/GivebutterFormProvider';
import { UseFormLabel } from 'components/form/UseFormLabel';
import { useGivebutterForm } from 'components/form/useGivebutterForm';
import { UseFormCheckboxInput } from 'components/checkbox';
import * as Label from 'components/label';

interface CampaignModalProps {
    show?: boolean; // legacy api
    hide?: () => void; // legacy api
    children?: ReactNode;
}

interface CampaignModalForm {
    campaignName: string;
    type?: EventType;
    enableTeamFundraising: boolean;
}

const CampaignModal = (props: CampaignModalProps) => {
    const { children, hide, show = false } = props;
    const { accountId } = useCurrentAccount();
    const history = useHistory();
    const [isOpen, setIsOpen] = useState(show);
    const methods = useGivebutterForm<CampaignModalForm>({
        defaultValues: {
            campaignName: '',
            type: undefined,
            enableTeamFundraising: false,
        },
    });
    const {
        control,
        handleSubmit,
        watch,
        setValue,
        setError,
        clearErrors,
        formState: { errors },
        reset,
        path,
    } = methods;

    useEffect(() => {
        setIsOpen(show);
    }, [show]);

    const eventType = watch('type');
    const apiError = (errors as any)['api-error']?.message;

    useEffect(() => {
        if (!!eventType) {
            setValue('enableTeamFundraising', false);
        }
    }, [eventType, setValue]);

    const { isLoading, mutate: createNewCampaign } = useMutation(
        (formData: CampaignModalForm) =>
            createCampaign(accountId, {
                type: formData.type!,
                enable_team_fundraising: formData.enableTeamFundraising,
                title: formData.campaignName,
            }),
        {
            onSuccess: ({ campaign }) => {
                setIsOpen(false);
                onClose();
                message.success('Created campaign successfully!');
                history.push(`/accounts/${accountId}/campaigns/${campaign.id}/details`);
            },
            onError: () => {
                setError('api-error' as FieldPath<CampaignModalForm>, {
                    type: 'custom',
                    message: 'Failed to create campaign. Please try again.',
                });
            },
        }
    );

    const onClose = () => {
        reset();
        hide && hide();
    };

    const onSubmit = (data: CampaignModalForm) => {
        createNewCampaign(data);
    };

    return (
        <GivebutterFormProvider {...methods}>
            <Dialog.Root
                open={isOpen}
                onOpenChange={open => {
                    if (!open) {
                        onClose();
                    }

                    setIsOpen(open);
                }}
            >
                {children}
                <Dialog.Portal>
                    <Dialog.Overlay />
                    <Dialog.Content
                        className={`pt-10 transition-[max-width] duration-200 ease-in-out`}
                        style={{
                            maxWidth: eventType ? '500px' : '1000px',
                        }}
                    >
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Dialog.Body className={'grid grid-cols-12 gap-6'}>
                                {eventType && (
                                    <div className={'col-span-full flex flex-col gap-y-4'}>
                                        <button
                                            type="button"
                                            onClick={() => setValue('type', undefined)}
                                            className={
                                                'absolute top-4 left-4 flex items-center gap-x-2 whitespace-nowrap font-medium text-gray-500'
                                            }
                                        >
                                            <FontAwesomeIcon icon={faArrowLeft} />
                                            Start Over
                                        </button>
                                        <div className={'flex flex-col gap-y-2 pt-4'}>
                                            <UseFormLabel name={path('campaignName')}>
                                                Campaign Title
                                            </UseFormLabel>
                                            <UseFormTextInput
                                                name={'campaignName'}
                                                control={control}
                                                rules={{
                                                    required: 'Campaign title is required.',
                                                }}
                                                autoFocus
                                            />
                                            <RenderOrNull
                                                cond={
                                                    eventType === 'fundraise' ||
                                                    eventType === 'event'
                                                }
                                            >
                                                <Label.Root
                                                    className={
                                                        'flex w-fit cursor-pointer items-center gap-2'
                                                    }
                                                >
                                                    <UseFormCheckboxInput
                                                        name={'enableTeamFundraising'}
                                                        control={control}
                                                    />
                                                    Enable Peer-to-Peer fundraising
                                                </Label.Root>
                                            </RenderOrNull>
                                        </div>
                                        {apiError && (
                                            <AlertNotification
                                                closable={true}
                                                type={'error'}
                                                onClose={() =>
                                                    clearErrors(
                                                        'api-error' as FieldPath<CampaignModalForm>
                                                    )
                                                }
                                            >
                                                {apiError}
                                            </AlertNotification>
                                        )}
                                        <button
                                            disabled={isLoading}
                                            type={'submit'}
                                            className={
                                                'flex h-10 items-center justify-center gap-x-4 rounded-lg bg-blue-500 text-base font-semibold text-white hover:bg-blue-600 disabled:opacity-60'
                                            }
                                        >
                                            <RenderOrNull cond={isLoading}>
                                                <FontAwesomeIcon
                                                    className={'animate-spin'}
                                                    icon={faSpinnerThird}
                                                />
                                            </RenderOrNull>
                                            <p>Create Campaign</p>
                                        </button>
                                    </div>
                                )}
                                {!eventType && (
                                    <>
                                        <div className="col-span-full mb-4 text-center">
                                            <p className={'text-3xl font-semibold text-black'}>
                                                Choose a campaign type
                                            </p>
                                            <p className={'text-base text-gray-600'}>
                                                Don't know what to select?{' '}
                                                <a
                                                    className={'font-semibold text-blue-500'}
                                                    href={CAMPAIGN_TYPE_LINK}
                                                    target={'_blank'}
                                                    rel={'noreferrer'}
                                                >
                                                    Learn more
                                                </a>
                                            </p>
                                        </div>
                                        <div
                                            className={
                                                'col-span-full flex flex-col rounded-xl border drop-shadow-sm md:col-span-4'
                                            }
                                        >
                                            <div className={'flex flex-col gap-y-1 px-6 pt-8'}>
                                                <p className={'text-2xl font-semibold'}>Form</p>
                                                <p className={'text-base'}>
                                                    The simplest solution for receiving donations.
                                                </p>
                                            </div>
                                            <hr className={'mx-6 my-4'} />
                                            <div className={'flex flex-col'}>
                                                <div className={'flex flex-col'}>
                                                    <div
                                                        className={
                                                            'flex items-center gap-x-4 px-6 py-2'
                                                        }
                                                    >
                                                        <div className={'aspect-square h-8'}>
                                                            <Form1 />
                                                        </div>
                                                        <p className={'text-base'}>
                                                            Custom branding
                                                        </p>
                                                    </div>
                                                    <div
                                                        className={
                                                            'flex items-center gap-x-4 px-6 py-2'
                                                        }
                                                    >
                                                        <div className={'aspect-square h-8'}>
                                                            <Form2 />
                                                        </div>
                                                        <p className={'text-base'}>Form Elements</p>
                                                    </div>
                                                    <div
                                                        className={
                                                            'flex items-center gap-x-4 px-6 py-2'
                                                        }
                                                    >
                                                        <div className={'aspect-square h-8'}>
                                                            <Form3 />
                                                        </div>
                                                        <p className={'text-base'}>Venmo & More</p>
                                                    </div>
                                                    <div
                                                        className={
                                                            'flex items-center gap-x-4 px-6 pt-2'
                                                        }
                                                    >
                                                        <div className={'aspect-square h-8'}>
                                                            <Form4 />
                                                        </div>
                                                        <p className={'text-base'}>
                                                            Recurring Donations
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className={'px-6 pt-4 pb-6'}>
                                                    <button
                                                        type="button"
                                                        className={
                                                            'h-10 w-full rounded-lg border border-gray-300 text-base font-semibold hover:cursor-pointer hover:bg-gray-100'
                                                        }
                                                        onClick={() => setValue('type', 'collect')}
                                                    >
                                                        Start a Form
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className={
                                                'col-span-full flex flex-col rounded-xl border drop-shadow-sm md:col-span-4'
                                            }
                                        >
                                            <div className={'flex flex-col gap-y-1 px-6 pt-8'}>
                                                <p className={'text-2xl font-semibold'}>Page</p>
                                                <p className={'text-base'}>
                                                    Crowdfunding around a set goal or story.
                                                </p>
                                            </div>
                                            <hr className={'mx-6 my-4'} />
                                            <div className={'flex flex-col'}>
                                                <div className={'flex flex-col '}>
                                                    <div
                                                        className={
                                                            'flex items-center gap-x-4 px-6 py-2'
                                                        }
                                                    >
                                                        <div className={'aspect-square h-8'}>
                                                            <Page1 />
                                                        </div>
                                                        <p className={'text-base font-semibold'}>
                                                            All Features of Forms
                                                        </p>
                                                    </div>
                                                    <div
                                                        className={
                                                            'relative flex items-center gap-x-4 bg-gray-100 px-6 py-2'
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                'absolute -top-4 right-2 aspect-square h-10'
                                                            }
                                                        >
                                                            <NewSticker />
                                                        </div>
                                                        <div className={'aspect-square h-8'}>
                                                            <AuctionIcon />
                                                        </div>
                                                        <p className={'text-base'}>Auctions</p>
                                                    </div>
                                                    <div
                                                        className={
                                                            'flex items-center gap-x-4 px-6 py-2'
                                                        }
                                                    >
                                                        <div className={'aspect-square h-8'}>
                                                            <Page3 />
                                                        </div>
                                                        <p className={'text-base'}>Peer-to-Peer</p>
                                                    </div>
                                                    <div
                                                        className={
                                                            'flex items-center gap-x-4 px-6 pt-2'
                                                        }
                                                    >
                                                        <div className={'aspect-square h-8'}>
                                                            <Page4 />
                                                        </div>
                                                        <p className={'text-base'}>
                                                            Supporter Feed
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className={'px-6 pt-4 pb-6'}>
                                                    <button
                                                        type="button"
                                                        className={
                                                            'h-10 w-full rounded-lg border border-gray-300 text-base font-semibold hover:cursor-pointer hover:bg-gray-100'
                                                        }
                                                        onClick={() =>
                                                            setValue('type', 'fundraise')
                                                        }
                                                    >
                                                        Start a Page
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className={
                                                'col-span-full flex flex-col rounded-xl border drop-shadow-sm md:col-span-4'
                                            }
                                        >
                                            <div className={'flex flex-col gap-y-1 px-6 pt-8'}>
                                                <p className={'text-2xl font-semibold'}>Event</p>
                                                <p className={'text-base'}>
                                                    For ticketed in-person, virtual, or hybrid
                                                    fundraisers.
                                                </p>
                                            </div>
                                            <hr className={'mx-6 my-4'} />
                                            <div className={'flex flex-col'}>
                                                <div className={'flex flex-col '}>
                                                    <div
                                                        className={
                                                            'flex items-center gap-x-4 px-6 py-2'
                                                        }
                                                    >
                                                        <div className={'aspect-square h-8'}>
                                                            <Event1 />
                                                        </div>
                                                        <p className={'text-base font-semibold'}>
                                                            All Features of Pages
                                                        </p>
                                                    </div>
                                                    <div
                                                        className={
                                                            'relative flex items-center gap-x-4 bg-gray-100 px-6 py-2'
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                'absolute -top-4 right-2 aspect-square h-10'
                                                            }
                                                        >
                                                            <NewSticker />
                                                        </div>
                                                        <div className={'aspect-square h-8'}>
                                                            <AuctionIcon />
                                                        </div>
                                                        <p className={'text-base'}>Auctions</p>
                                                    </div>
                                                    <div
                                                        className={
                                                            'flex items-center gap-x-4 px-6 py-2'
                                                        }
                                                    >
                                                        <div className={'aspect-square h-8'}>
                                                            <Event3 />
                                                        </div>
                                                        <p className={'text-base'}>
                                                            Tickets & Items
                                                        </p>
                                                    </div>
                                                    <div
                                                        className={
                                                            'flex items-center gap-x-4 px-6 pt-2'
                                                        }
                                                    >
                                                        <div className={'aspect-square h-8'}>
                                                            <Event4 />
                                                        </div>
                                                        <p className={'text-base'}>
                                                            Live Streaming
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className={'px-6 pt-4 pb-6'}>
                                                    <button
                                                        type="button"
                                                        className={
                                                            'h-10 w-full rounded-lg border border-gray-300 text-base font-semibold hover:cursor-pointer hover:bg-gray-100'
                                                        }
                                                        onClick={() => setValue('type', 'event')}
                                                    >
                                                        Start an Event
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </Dialog.Body>
                        </form>
                        <Dialog.Close>
                            <FontAwesomeIcon className="h-6 w-6" icon={faTimes} />
                        </Dialog.Close>
                    </Dialog.Content>
                </Dialog.Portal>
            </Dialog.Root>
        </GivebutterFormProvider>
    );
};

const CampaignModalTrigger = Dialog.Trigger;

const Root = CampaignModal;
const Trigger = CampaignModalTrigger;

export {
    CampaignModal,
    CampaignModalTrigger,
    //
    Root,
    Trigger,
};
export type { CampaignModalProps };
