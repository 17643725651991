import Axios from 'axios';
import {
    Auction,
    AuctionItem,
    Bid,
    BidStatus,
    Bidder,
    Campaign,
    Member,
    Team,
    EventType,
    PromoCode,
    PromoCodeType,
    CampaignItem,
    CampaignExpert,
} from 'types/domain';
import { PaginatedData } from 'types/pagination';
import { FilterGroupRequest, OutboundFilterRequest } from 'components/filter';
import qs from 'qs';

// PARAMS
interface HasCampaignIdParam {
    campaignId: number;
}
interface HasAuctionIdParam {
    auctionId: number;
}
interface HasCampaignItemIdParam {
    itemId: number;
}
interface HasBidderIdParam {
    bidderId: number;
}
interface HasPage {
    page?: number;
}
interface HasPaginationParams extends HasPage {
    limit?: number;
    query?: string;
}

interface MediaUpload {
    id?: number;
    url: string;
    size: number;
    mime_type: string;
    collection?: string;
}

interface ItemRequest extends Omit<Partial<AuctionItem>, 'pictures'> {
    pictures?: MediaUpload[];
}
interface HasItemRequest {
    data: ItemRequest;
}
interface HasBidderRequest {
    data?: Partial<Bidder>;
}

// CAMPAIGNS
interface FetchCampaignsParams extends HasPaginationParams {
    scope?: 'all';
    filters?: FilterGroupRequest<OutboundFilterRequest>[];
    sortBy?: string;
    sortByDesc?: string;
    query?: string;
    excl_type?: string;
}

interface UpdateCampaignParams<T> extends HasCampaignIdParam {
    data: T;
}

// RESOURCES
interface FetchCampaignResourceParams extends HasCampaignIdParam, HasPaginationParams {}
interface DeleteCampaignResourceParams extends HasCampaignIdParam {
    resourceId: number;
}

// ITEMS
interface FetchCampaignItemsParams extends HasCampaignIdParam, HasPage {
    auction_id?: number;
    type?: 'ticket' | 'product';
    query?: string;
    per_page?: number;
    sortBy?: string;
    sortByDesc?: string;
}
interface FetchCampaignItemParams extends HasCampaignIdParam, HasCampaignItemIdParam {}
interface CreateCampaignItemParams extends HasCampaignIdParam, HasItemRequest {
    type?: 'ticket' | 'product';
}
interface UpdateCampaignItemParams
    extends HasCampaignIdParam,
        HasCampaignItemIdParam,
        HasItemRequest {}
interface CancelCampaignItemParams extends HasCampaignIdParam, HasCampaignItemIdParam {}
interface DeleteCampaignItemParams extends HasCampaignIdParam, HasCampaignItemIdParam {}
interface ReorderCampaignItemsParams {
    order: string;
}

// PROMO CODES
interface FetchCampaignPromoCodesParams {
    query?: string;
    page?: number;
    sortBy?: string;
    sortByDesc?: string;
}

interface PromoCodeRequest {
    code: string;
    type: PromoCodeType;
    amount: number;
    uses: number | null;
    active: boolean;
    items: number[] | null;
}

// AUCTIONS
interface FetchAuctionParams extends HasCampaignIdParam, HasAuctionIdParam {}
interface CreateAuctionParams<T> extends HasCampaignIdParam {
    data: T;
}
interface UpdateAuctionParams<T> extends HasCampaignIdParam, HasAuctionIdParam {
    data: T;
}
interface DeleteAuctionParams extends HasCampaignIdParam, HasAuctionIdParam {}
interface CancelAuctionParams extends HasCampaignIdParam, HasAuctionIdParam {}

// BIDS
type BidStatusFilter = BidStatus | 'all_winners';
interface FetchCampaignItemBidsParams
    extends HasCampaignIdParam,
        HasCampaignItemIdParam,
        HasPaginationParams {
    status?: BidStatusFilter;
}
interface FetchAuctionBidsParams extends HasCampaignIdParam, HasAuctionIdParam, HasPage {
    status?: BidStatusFilter;
    query?: string;
}

interface CreateCampaignItemBidBody {
    bidder_number: number;
    initial_amount: number;
    max_amount?: number;
    quantity?: number;
}

// BIDDERS
interface FetchAuctionBiddersParams extends HasCampaignIdParam, HasAuctionIdParam {}
interface UpdateAuctionBidderParams
    extends HasCampaignIdParam,
        HasAuctionIdParam,
        HasBidderIdParam,
        HasBidderRequest {}
interface BanAuctionBidderParams extends HasCampaignIdParam, HasAuctionIdParam, HasBidderIdParam {}

interface CreateCampaignParams {
    title: string;
    type: EventType;
    enable_team_fundraising: boolean;
}

interface CreateCampaignResponse {
    access: object;
    campaign: Campaign;
}

interface FetchSponsorParams {
    page?: number;
    perPage?: number;
}

interface MarkUncollectableParams {
    secondChanceBidId?: number;
}

interface FetchCampaignExpertsParams {
    isAvailable?: boolean;
}

// CAMPAIGNS
const createCampaign = (accountId: number, params: CreateCampaignParams) =>
    Axios.post<CreateCampaignResponse>('/campaigns', {
        account_id: accountId,
        ...params,
    }).then(response => response.data);

const fetchCampaigns = (accountId: number, params: FetchCampaignsParams = {}) => {
    const { filters, ...restParams } = params;
    const stringifiedFilters = filters ? qs.stringify(filters) : '';

    return Axios.get<PaginatedData<Campaign>>(
        `/accounts/${accountId}/campaigns?${stringifiedFilters}`,
        {
            params: restParams,
        }
    ).then(response => response.data);
};

const fetchCampaign = ({ campaignId }: { campaignId: number }) =>
    Axios.get<Campaign>(`/campaigns/${campaignId}`).then(response => response.data);

const duplicateCampaign = (campaignId: number) =>
    Axios.post<Campaign>(`/campaigns/${campaignId}/duplicate`).then(response => response.data);

const deleteCampaign = (campaignId: number) =>
    Axios.delete<Campaign>(`/campaigns/${campaignId}`).then(response => response.data);

const updateCampaign = <TData>({ campaignId, data }: UpdateCampaignParams<TData>) =>
    Axios.patch(`/campaigns/${campaignId}`, data).then(response => response.data);

// CAMPAIGN RESOURCES
const fetchCampaignTeams = ({ campaignId, ...params }: FetchCampaignResourceParams) =>
    Axios.get<PaginatedData<Team>>(`/campaigns/${campaignId}/teams`, {
        params,
    }).then(response => response.data);

const fetchCampaignMembers = ({ campaignId, ...params }: FetchCampaignResourceParams) =>
    Axios.get<PaginatedData<Member>>(`/campaigns/${campaignId}/members`, {
        params,
    }).then(response => response.data);

const deleteCampaignTeam = ({ campaignId, resourceId }: DeleteCampaignResourceParams) =>
    Axios.delete(`/campaigns/${campaignId}/teams/${resourceId}`);

const deleteCampaignMember = ({ campaignId, resourceId }: DeleteCampaignResourceParams) =>
    Axios.delete(`/campaigns/${campaignId}/members/${resourceId}`);

// ITEMS
const fetchCampaignItems = <T = CampaignItem>({
    campaignId,
    ...params
}: FetchCampaignItemsParams) =>
    Axios.get<PaginatedData<T>>(`/campaigns/${campaignId}/items`, {
        params,
    }).then(response => response.data);

const fetchCampaignItem = <T = CampaignItem>({
    campaignId,
    itemId,
    ...params
}: FetchCampaignItemParams) =>
    Axios.get<T>(`/campaigns/${campaignId}/items/${itemId}`, { params }).then(
        response => response.data
    );

const createCampaignItem = ({ campaignId, data, ...params }: CreateCampaignItemParams) =>
    Axios.post<AuctionItem>(`/campaigns/${campaignId}/items`, data, { params }).then(
        response => response.data
    );

const updateCampaignItem = ({ campaignId, itemId, data, ...params }: UpdateCampaignItemParams) =>
    Axios.patch<AuctionItem>(`/campaigns/${campaignId}/items/${itemId}`, data, { params }).then(
        response => response.data
    );

const cancelCampaignItem = ({ campaignId, itemId }: CancelCampaignItemParams) =>
    Axios.post(`/campaigns/${campaignId}/items/${itemId}/cancel`).then(response => response.data);

const deleteCampaignItem = ({ campaignId, itemId }: DeleteCampaignItemParams) =>
    Axios.delete(`/campaigns/${campaignId}/items/${itemId}`).then(response => response.data);

const reorderCampaignItems = (campaignId: number, data: ReorderCampaignItemsParams) =>
    Axios.post(`/campaigns/${campaignId}/items/reorder`, data).then(response => response.data);

// PROMO CODES

const fetchCampaignPromoCodes = (campaignId: number, params: FetchCampaignPromoCodesParams = {}) =>
    Axios.get<PaginatedData<PromoCode>>(`/campaigns/${campaignId}/discount_codes`, { params }).then(
        response => response.data
    );

const fetchCampaignPromoCode = (campaignId: number, promoCodeId: number) =>
    Axios.get<PromoCode>(`/campaigns/${campaignId}/discount_codes/${promoCodeId}`).then(
        response => response.data
    );

const createCampaignPromoCode = (campaignId: number, data: PromoCodeRequest) =>
    Axios.post(`/campaigns/${campaignId}/discount_codes`, data).then(response => response.data);

const updateCampaignPromoCode = (campaignId: number, promoCodeId: number, data: PromoCodeRequest) =>
    Axios.patch(`/campaigns/${campaignId}/discount_codes/${promoCodeId}`, data).then(
        response => response.data
    );

const deleteCampaignPromoCode = (campaignId: number, promoCodeId: number) =>
    Axios.delete(`/campaigns/${campaignId}/discount_codes/${promoCodeId}`).then(
        response => response.data
    );

// AUCTIONS
const fetchCampaignAuction = ({ campaignId, auctionId }: FetchAuctionParams) =>
    Axios.get<Auction>(`/campaigns/${campaignId}/auctions/${auctionId}`).then(
        response => response.data
    );

const createCampaignAuction = <T>({ campaignId, data }: CreateAuctionParams<T>) =>
    Axios.post(`/campaigns/${campaignId}/auctions`, data).then(response => response.data);

const updateCampaignAuction = <T>({ campaignId, auctionId, data }: UpdateAuctionParams<T>) =>
    Axios.patch(`/campaigns/${campaignId}/auctions/${auctionId}`, data).then(
        response => response.data
    );

const deleteCampaignAuction = ({ campaignId, auctionId }: DeleteAuctionParams) =>
    Axios.delete(`/campaigns/${campaignId}/auctions/${auctionId}`).then(response => response.data);

const cancelCampaignAuction = ({ campaignId, auctionId }: CancelAuctionParams) =>
    Axios.post(`/campaigns/${campaignId}/auctions/${auctionId}/cancel`).then(
        response => response.data
    );

// BIDS
const fetchCampaignItemBids = ({ campaignId, itemId, ...params }: FetchCampaignItemBidsParams) =>
    Axios.get<PaginatedData<Bid>>(`/campaigns/${campaignId}/items/${itemId}/bids`, { params }).then(
        response => response.data
    );

const fetchAuctionBids = ({ campaignId, auctionId, ...params }: FetchAuctionBidsParams) =>
    Axios.get<PaginatedData<Bid>>(`/campaigns/${campaignId}/auctions/${auctionId}/bids`, {
        params,
    }).then(response => response.data);

const markUncollectable = (
    campaignId: number,
    auctionId: number,
    bidId: number,
    params?: MarkUncollectableParams
) =>
    Axios.post<Bid>(`/campaigns/${campaignId}/auctions/${auctionId}/bids/${bidId}/disqualify`, {
        second_chance_bid_id: params?.secondChanceBidId,
    }).then(response => response.data);

const chargeBidder = (campaignId: number, auctionId: number, bidId: number) =>
    Axios.post<Bid>(`/campaigns/${campaignId}/auctions/${auctionId}/bids/${bidId}/charge`).then(
        response => response.data
    );

const createItemBid = (campaignId: number, itemId: number, body: CreateCampaignItemBidBody) =>
    Axios.post<Bid>(`/campaigns/${campaignId}/items/${itemId}/bids`, body).then(
        response => response.data
    );

// BIDDERS
const fetchAuctionBidders = ({ campaignId, auctionId, ...params }: FetchAuctionBiddersParams) =>
    Axios.get<PaginatedData<Bidder>>(`/campaigns/${campaignId}/auctions/${auctionId}/bidders`, {
        params,
    }).then(response => response.data);

const invoiceBidder = (campaignId: number, auctionId: number, bidderId: number) =>
    Axios.post<Bidder>(
        `/campaigns/${campaignId}/auctions/${auctionId}/bidders/${bidderId}/invoice`
    ).then(response => response.data);

const updateAuctionBidder = ({
    campaignId,
    auctionId,
    bidderId,
    data = {},
}: UpdateAuctionBidderParams) =>
    Axios.patch<Bidder>(
        `/campaigns/${campaignId}/auctions/${auctionId}/bidders/${bidderId}`,
        data
    ).then(response => response.data);

const auctionBanFactory = (shouldBan: Boolean) => {
    return (params: BanAuctionBidderParams) => {
        return shouldBan ? banAuctionBidder(params) : unbanAuctionBidder(params);
    };
};

const banAuctionBidder = ({ campaignId, auctionId, bidderId }: BanAuctionBidderParams) =>
    Axios.post<Bidder>(
        `/campaigns/${campaignId}/auctions/${auctionId}/bidders/${bidderId}/ban`
    ).then(response => response.data);

const unbanAuctionBidder = ({ campaignId, auctionId, bidderId }: BanAuctionBidderParams) =>
    Axios.post<Bidder>(
        `/campaigns/${campaignId}/auctions/${auctionId}/bidders/${bidderId}/unban`
    ).then(response => response.data);

const fetchSponsors = (accountId: number, { page = 1 }: FetchSponsorParams) =>
    Axios.get(`/accounts/${accountId}/sponsors`, {
        params: { page },
    }).then(response => response.data);

const fetchSponsorTemplates = (
    accountId: number,
    sponsorId: number,
    { page = 1 }: FetchSponsorParams
) =>
    Axios.get(`/accounts/${accountId}/sponsors/${sponsorId}/items`, {
        params: { page },
    }).then(response => response.data);

const fetchTemplates = (
    accountId: number,
    sponsorId: number,
    { page = 1, perPage }: FetchSponsorParams
) =>
    Axios.get(`/accounts/${accountId}/sponsors/search`, {
        params: { page, per_page: perPage, sponsor: sponsorId },
    }).then(response => response.data);

const acceptSponsorTerms = (accountId: number, sponsorId: number) =>
    Axios.post(`/accounts/${accountId}/sponsors/${sponsorId}/accept_terms`).then(
        response => response.data
    );

const fetchSponsorTemplateById = (accountId: number, sponsorId: number, templateId: number) =>
    Axios.get(`/accounts/${accountId}/sponsors/${sponsorId}/items/${templateId}`).then(
        response => response.data
    );

const sendTestCampaignEmail = (campaignId: number) =>
    Axios.post(`/campaigns/${campaignId}/actions?action=send_test_email`).then(
        response => response.data
    );

const fetchCampaignExperts = (campaignId: number, params: FetchCampaignExpertsParams = {}) =>
    Axios.get<CampaignExpert[]>(`/campaigns/${campaignId}/experts`, {
        params: { available: params?.isAvailable },
    }).then(response => response.data);

const deleteCampaignExpertById = (campaignId: number, expertId: number) =>
    Axios.delete(`/campaigns/${campaignId}/experts/${expertId}`).then(response => response.data);

const linkExpertToCampaign = (campaignId: number, expertId: number) =>
    Axios.post(`/campaigns/${campaignId}/experts`, {
        partner_id: expertId,
    }).then(response => response.data);

export {
    fetchSponsors,
    fetchSponsorTemplateById,
    fetchSponsorTemplates,
    fetchTemplates,
    acceptSponsorTerms,
    sendTestCampaignEmail,
    //
    createCampaign,
    fetchCampaigns,
    fetchCampaign,
    updateCampaign,
    duplicateCampaign,
    deleteCampaign,
    //
    fetchCampaignTeams,
    deleteCampaignTeam,
    //
    fetchCampaignMembers,
    deleteCampaignMember,
    //
    fetchCampaignItems,
    fetchCampaignItem,
    createCampaignItem,
    updateCampaignItem,
    cancelCampaignItem,
    deleteCampaignItem,
    reorderCampaignItems,
    //
    fetchCampaignPromoCodes,
    fetchCampaignPromoCode,
    createCampaignPromoCode,
    updateCampaignPromoCode,
    deleteCampaignPromoCode,
    //
    fetchCampaignAuction,
    createCampaignAuction,
    updateCampaignAuction,
    deleteCampaignAuction,
    cancelCampaignAuction,
    //
    createItemBid,
    fetchCampaignItemBids,
    fetchAuctionBids,
    markUncollectable,
    chargeBidder,
    //
    fetchAuctionBidders,
    updateAuctionBidder,
    banAuctionBidder,
    unbanAuctionBidder,
    invoiceBidder,
    auctionBanFactory,
    //
    fetchCampaignExperts,
    deleteCampaignExpertById,
    linkExpertToCampaign,
};

export type {
    MediaUpload,
    //
    FetchCampaignsParams,
    FetchCampaignResourceParams,
    UpdateCampaignParams,
    DeleteCampaignResourceParams,
    PaginatedData,
    CreateAuctionParams,
    UpdateAuctionParams,
    ItemRequest,
    PromoCodeRequest,
    //
    FetchCampaignItemsParams,
    FetchCampaignItemParams,
    CreateCampaignItemParams,
    UpdateCampaignItemParams,
    DeleteCampaignItemParams,
    BidStatusFilter,
    FetchCampaignExpertsParams,
};
