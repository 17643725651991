import * as PopoverPrimitive from '@radix-ui/react-popover';
import styled, { css } from 'styled-components';

const PopoverContentStyles = css`
    z-index: ${props => props.theme.zIndex.PentOutermost};
    margin: 0;
    border-radius: ${props => props.theme.radius.large};
    box-shadow: ${props => props.theme.shadows.popover};
    border: none;
    background-color: white;
`;

const Content = styled(PopoverPrimitive.Content)`
    ${PopoverContentStyles};
`;

export { PopoverContentStyles, Content };
