const ZIndex = {
    Outermost: 2147483647,
    PentOutermost: 2147483646,
    LayerTen: 99999999,
    LayerNine: 99999999,
    LayerEight: 9999999,
    LayerSeven: 999999,
    LayerSix: 99999,
    LayerFive: 9999,
    LayerFour: 999,
    LayerThree: 99,
    LayerOne: 1,
    Zero: 0,
    BelowZero: -1,
    TinyMce: 2147483639,
};

export { ZIndex };
