import { StyledProps, css } from 'styled-components';
import { FocusVisible } from 'components/util/FocusVisibleStyles';
import { FormLike } from 'components/util/types';

const ACTION_BORDER_WIDTH = 2;

const BorderFactory = (color: string, borderSize = 1) => css`
    box-shadow: 0 0 0 ${ACTION_BORDER_WIDTH} ${color};

    &::before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        border: ${borderSize}px solid ${color};
        border-radius: ${props => props.theme.radius.default};
        z-index: ${props => props.theme.zIndex.LayerOne};
        pointer-events: none;
    }
`;

const InputFocusBorder = css`
    ${props => BorderFactory(props.theme.color.cobalt, ACTION_BORDER_WIDTH)};
`;

const InputErrorBorder = css`
    ${props => BorderFactory(props.theme.color.error, ACTION_BORDER_WIDTH)};
`;

const GrayBorder = css`
    ${BorderFactory('#e7e7e7')};
`;

const Disabled = css`
    cursor: not-allowed;
    opacity: 0.8;
    background-color: #fafafa;
    ${GrayBorder};
`;

const InputLikeStyles = css<StyledProps<FormLike>>`
    width: inherit;
    border: none;
    position: relative;
    line-height: 1rem;
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    border-radius: ${props => props.theme.radius.default};
    ${GrayBorder};

    &:focus {
        ${InputFocusBorder};
    }

    &:focus-visible {
        outline: none !important;
        ${FocusVisible};
    }

    &[disabled],
    &[readonly] {
        ${Disabled};
    }

    ${props =>
        props.disabled &&
        css`
            ${Disabled};
        `}

    ${props =>
        props.error &&
        css`
            ${InputErrorBorder};
        `}
`;

export { Disabled, InputErrorBorder, InputFocusBorder, InputLikeStyles, GrayBorder };
