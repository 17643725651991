import React from 'react';
import Spinner from 'assets/js/components/Spinner';

interface PageLoadingProps {
    spin: boolean;
}
class PageLoading extends React.Component<PageLoadingProps> {
    static defaultProps = {
        spin: false,
    };

    render() {
        const { spin } = this.props;

        return (
            <div id="page-loading">
                <div>
                    {!spin && (
                        <React.Fragment>
                            <p style={{ marginBottom: '20px' }}>
                                <img
                                    width={268}
                                    alt="Givebutter Dashboard"
                                    src={`${process.env.PUBLIC_URL}/images/logo-orange.png`}
                                />
                            </p>

                            <p style={{ color: '#000', fontSize: '15px', marginBottom: '0px' }}>
                                Loading your dashboard...
                            </p>
                        </React.Fragment>
                    )}

                    {spin && <Spinner />}
                </div>
            </div>
        );
    }
}

export default PageLoading;
