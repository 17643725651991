import { useEffect, useState } from 'react';
import { DefaultTheme, Mode, SizeObject } from 'styled-components';
import { ProductColors } from 'components/util';
import { ZIndex } from 'components/util/ZIndex';
import { hexToRgbA } from './colors';
import { getFromLS, setToLS } from './local-storage';

const sizes = {
    fonts: {
        xs: '12px',
        sm: '13px',
        md: '15px',
        lg: '18px',
        xl: '20px',
        xxl: '24px',
        xxxl: '30px',
        huge: '40px',
    },
    spacers: {
        xxxs: '4px',
        xxs: '8px',
        xs: '12px',
        sm: '14px',
        md: '16px',
        lg: '18px',
        xl: '24px',
        xxl: '32px',
        xxxl: '40px',
        huge: '60px',
    },
    avatar: {
        sm: '24px',
        md: '32px',
        lg: '40px',
        xl: '48px',
    },
    button: {
        height: {
            sm: '32px',
            md: '40px',
            lg: '50px',
        },
        font: {
            sm: '12px',
            md: '16px',
            lg: '20px',
        },
    },
};

const typography = {
    font: 'Matter',
    body: {
        size: '14px',
        weight: '400',
        height: '15px',
    },
};

const color = {
    butterLight: '#FFBF00',
    caution: '#FF8934',
    cautionFaded: 'rgba(255, 137, 52, 0.1)',
    cobalt: '#3366FF',
    cobaltDark: '#2952CC',
    cobaltLight: '#C2D1FF',
    cobaltFaded: 'rgba(243, 246, 255, 0.7)',
    darkModeGray: '#343638',
    onyx: '#222222',
    error: '#FF3C3C',
    errorDark: '#E03535',
    errorFaded: 'rgba(255, 60, 60, 0.1)',
    slate: '#D9D9D9',
    slateDark: '#767676',
    slateLight: '#F2F2F2',
    slateLightest: '#F7F7F7',
    success: '#2BA25D',
    successFaded: 'rgba(43, 162, 93, 0.1)',
    white: '#FFFFFF',
    separator: '#F2F2F2',
};

const radius = {
    default: '8px',
    large: '12px',
};

const shadows = {
    default: '0px 2px 4px rgba(0, 0, 0, 0.05)',
    large: '0px 5px 30px rgba(0, 0, 0, 0.08)',
    popover: '0 -1px 20px rgba(0, 0, 0, 0.05), 0 1px 5px rgba(0, 0, 0, 0.15)',
    input: `0 0 0 2px ${hexToRgbA(color.cobalt, 0.07)}`,
};

const defaultColors = {
    ...color,
    white: '#ffffff',
    black: '#000000',
    green: '#2BA25D',
};

const productColors: ProductColors = {
    gray: {
        25: '#fcfcfd',
        50: '#f8fafc',
        100: '#eef2f6',
        200: '#e3e8ef',
        300: '#cdd5df',
        400: '#9aa4b2',
        500: '#697586',
        600: '#4b5565',
        700: '#364152',
        800: '#202939',
        900: '#121926',
    },
    neutral: {
        25: '#f3f6ff',
        50: '#eff4ff',
        100: '#d1e0ff',
        200: '#b2ccff',
        300: '#9fcefb',
        400: '#528bff',
        500: '#3366ff',
        600: '#155eef',
        700: '#004eeb',
        800: '#0040c1',
        900: '#00359e',
    },
    warning: {
        25: '#fffcf5',
        50: '#fffaeb',
        100: '#fef0c7',
        200: '#fedf89',
        300: '#fec84b',
        400: '#fdb022',
        500: '#f79009',
        600: '#dc6803',
        700: '#b54708',
        800: '#93370d',
        900: '#7a2e0e',
    },
    error: {
        25: '#fffbfa',
        50: '#fef3f2',
        100: '#fee4e2',
        200: '#fecdca',
        300: '#fda29b',
        400: '#f97066',
        500: '#f04438',
        600: '#d92d20',
        700: '#b42318',
        800: '#912018',
        900: '#7a271a',
    },
    success: {
        25: '#f6fef9',
        50: '#ecfdf3',
        100: '#d1fadf',
        200: '#a6f4c5',
        300: '#6ce9a6',
        400: '#32d583',
        500: '#12b76a',
        600: '#039855',
        700: '#027a48',
        800: '#05603a',
        900: '#054f31',
    },
};

const statuses = {
    succeeded: color.success,
    error: color.error,
    neutral: color.slate,
    warning: '#ffc000',
    pending: color.cobalt,
};

const badges = {
    primary: color.cobalt,
    transparent: 'transparent',
    secondary: color.darkModeGray,
};

const breakpoint = {
    mobile: '768px',
    tablet: '900px',
    laptop: '1100px',
    desktop: '1440px',
};

const utility = {
    transition: 'all cubic-bezier(0.16, 0.8, 0.45, 1) 0.3s',
    transform: 'transform cubic-bezier(0.16, 0.8, 0.45, 1) 0.2s',
};

const avatar = {
    orange: {
        primary: '#FF8934',
        secondary: '#FFF3EB',
    },
    purple: {
        primary: '#AB37F1',
        secondary: '#F7EBFE',
    },
    lightBlue: {
        primary: '#23A1FF',
        secondary: '#E9F6FF',
    },
    green: {
        primary: '#158858',
        secondary: '#E8F3EE',
    },
    pink: {
        primary: '#FF52BA',
        secondary: '#FFEEF8',
    },
    blueberry: {
        primary: '#0117DD',
        secondary: '#E6E8FC',
    },
    red: {
        primary: '#F02B2B',
        secondary: '#FEEAEA',
    },
    teal: {
        primary: '#13C2E9',
        secondary: '#E7F9FD',
    },
};

const reusableButtons = {
    error: {
        bg: statuses.error,
        bgHover: hexToRgbA(statuses.error, 0.7),
        border: statuses.error,
        borderHover: statuses.error,
        color: color.slateLight,
        icon: color.slateLight,
        iconHover: color.slateLight,
    },
    transparent: {
        bg: 'transparent',
        bgHover: hexToRgbA(color.slate, 0.1),
        border: color.slate,
        borderHover: color.slate,
        color: color.onyx,
        icon: color.onyx,
        iconHover: color.onyx,
    },
};

const fontSize: SizeObject = {
    sm: '14px',
    md: '16px',
    lg: '18px',
};

const padding: SizeObject = {
    sm: '6px 14px',
    md: '8px 16px',
    lg: '10px 18px',
};

const gap: SizeObject = {
    sm: '8px',
    md: '10px',
    lg: '12px',
};

const themes = {
    light: {
        colors: {
            badges,
            ...statuses,
            ...defaultColors,
            primary: {},
            secondary: {},
            background: {
                project: color.slateLightest,
                advertisement: color.slate,
            },
            text: {
                primary: color.onyx,
                secondary: color.slate,
            },
            table: {
                row: {
                    background: color.white,
                    shadow: shadows.default,
                },
                sort: color.slate,
                avatar,
                filter: {
                    background: color.white,
                },
            },
            pagination: {
                background: {
                    default: color.white,
                    active: color.cobaltFaded,
                },
                text: {
                    default: color.onyx,
                    active: color.cobalt,
                    disabled: color.slate,
                },
            },
            button: {
                primary: {
                    bg: color.cobalt,
                    bgHover: color.cobaltDark,
                    border: color.cobalt,
                    borderHover: color.cobaltDark,
                    color: color.white,
                    icon: color.cobaltLight,
                    iconHover: color.white,
                },
                secondary: {
                    bg: 'transparent',
                    bgHover: 'transparent',
                    border: color.slate,
                    borderHover: color.slate,
                    color: color.onyx,
                    icon: color.slate,
                    iconHover: color.onyx,
                },
                ...reusableButtons,
            },
            input: {
                color: color.onyx,
                placeholder: hexToRgbA(color.onyx, 0.3),
                border: {
                    default: color.slate,
                    focus: color.cobalt,
                    error: color.error,
                },
                background: {
                    default: color.white,
                    error: hexToRgbA(color.error, 0.05),
                },
            },
            icons: {
                default: color.onyx,
                primary: color.cobalt,
                secondary: color.slateLight,
                ...statuses,
            },
        },
    },
    dark: {
        colors: {
            badges,
            ...statuses,
            ...defaultColors,
            primary: {},
            secondary: {},
            background: {
                project: '#1D1D1D',
            },
            text: {
                primary: color.slateLight,
                secondary: color.darkModeGray,
            },
            table: {
                row: {
                    background: color.darkModeGray,
                    hover: '#27282A',
                    shadow: 'none',
                },
                sort: color.darkModeGray,
                avatar,
                filter: {
                    background: color.darkModeGray,
                },
            },
            pagination: {
                background: {
                    default: 'transparent',
                    active: color.darkModeGray,
                    hover: hexToRgbA('#454a4e', 0.25),
                },
                text: {
                    default: color.slateLight,
                    active: color.slateLight,
                    disabled: color.darkModeGray,
                },
            },
            button: {
                primary: {
                    bg: color.cobalt,
                    bgHover: color.cobaltDark,
                    border: color.cobalt,
                    borderHover: color.cobaltDark,
                    color: '#FFFFFF',
                    icon: hexToRgbA('#FFFFFF', 0.5),
                    iconHover: '#FFFFFF',
                },
                secondary: {
                    bg: 'transparent',
                    bgHover: color.darkModeGray,
                    border: color.darkModeGray,
                    borderHover: color.slateLight,
                    color: color.slateLight,
                    icon: color.darkModeGray,
                    iconHover: color.slateLight,
                },
                ...reusableButtons,
            },
            input: {
                color: '#F2F2F2',
                placeholder: color.slate,
                border: {
                    default: color.darkModeGray,
                    focus: color.cobalt,
                    error: color.error,
                },
                background: {
                    default: color.onyx,
                    error: hexToRgbA(color.error, 0.05),
                },
            },
            icons: {
                default: '#F2F2F2',
                primary: color.cobalt,
                secondary: '#1D1D1D',
                ...statuses,
            },
        },
    },
};

const modes = Object.keys(themes);

const useTheme = () => {
    const [theme, setTheme] = useState<Mode>('light');

    const changeTheme = () => {
        setTheme(mode => {
            const newMode = mode === 'light' ? 'dark' : 'light';
            setToLS('theme', newMode);
            return newMode;
        });
    };

    useEffect(() => {
        const lastTheme = getFromLS('theme');
        if (lastTheme && modes.includes(lastTheme)) {
            setTheme(lastTheme);
        } else {
            setToLS('theme', 'light');
        }
    }, []);

    return {
        theme: {
            mode: theme,
            breakpoint,
            sizes,
            statuses,
            radius,
            shadows,
            utility,
            typography,
            color,
            colors: themes[theme].colors,
            zIndex: ZIndex,
            fontSize,
            padding,
            gap,
        } as DefaultTheme,
        changeTheme,
    };
};

export { useTheme, themes, modes, defaultColors, productColors };
