import styled, { SizeObject, StyledProps, css } from 'styled-components';
import tinycolor from 'tinycolor2';
import { ellipsis, noSelect } from 'constants/styles';
import { defaultColors, productColors } from 'helpers/themes';
import { ButtonProps } from 'components/button2/Button';
import { useButton } from 'components/button2/ButtonContext';
import { Colorable, ProductColor, ProductColors } from 'components/util';

const heights: SizeObject = {
    sm: '33px',
    md: '40px',
    lg: '47px',
};

const getDefaultStyles = (props: StyledProps<ButtonProps>) => {
    const transition = props.theme.utility.transition;

    return css`
        max-width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        cursor: pointer;
        box-sizing: border-box;
        font-weight: 600;
        border-radius: ${p => p.theme.radius.default};
        transition: ${transition};
        height: ${heights[props.size!]};
        font-size: ${props.theme.fontSize[props.size!]};
        padding: ${props.theme.padding[props.size!]};
        gap: ${props.theme.gap[props.size!]};

        svg {
            transition: ${transition};
        }
    `;
};

const getButtonContextStyles = css<ButtonProps>`
    ${({ size }) => {
        const { hasOrphanIcon } = useButton();
        return (
            hasOrphanIcon &&
            css`
                padding: 0;
                width: ${heights[size!]};
            `
        );
    }}
`;

const getDisabledStyles = ({ disabled }: StyledProps<{ disabled?: boolean }>) => {
    if (!disabled) return '';
    return css`
        opacity: 0.5;
        cursor: default;
        pointer-events: none;
    `;
};

const getDefaultColors = css<ButtonProps>`
    ${({ variant, theme }) => {
        switch (variant) {
            case 'primary':
                return css`
                    color: ${defaultColors.white};
                    border: 1px solid ${productColors.neutral[500]};
                    background: ${productColors.neutral[500]};

                    &:hover {
                        border-color: ${productColors.neutral[700]};
                        background: ${productColors.neutral[700]};
                    }

                    &:active {
                        background: ${productColors.neutral[700]};
                    }
                `;

            case 'secondary':
                return css`
                    color: ${productColors.gray[900]};
                    border: 1px solid ${productColors.gray[300]};
                    background: ${defaultColors.white};

                    &:hover {
                        border-color: ${productColors.gray[300]};
                        background: ${productColors.gray[100]};
                    }

                    &:active {
                        background: ${productColors.gray[100]};
                    }
                `;

            case 'tertiary':
                return css`
                    color: ${productColors.gray[900]};
                    border: transparent;
                    background: transparent;

                    &:hover {
                        border-color: transparent;
                        background: transparent;
                    }

                    &:active {
                        background: transparent;
                    }
                `;
        }
    }}
`;

const getPrimaryColors = ({ color }: ButtonProps) => {
    const { backgroundColor, hoverColor, textColor } = buttonColorFactory(color);
    return css`
        color: ${textColor};
        background-color: ${backgroundColor};
        border-color: ${backgroundColor};

        &:hover {
            background-color: ${hoverColor};
            border-color: ${hoverColor};
        }
    `;
};

const DEFAULT_COLOR_WEIGHT = '500';
const buttonColorFactory = (color: Colorable['color']) => {
    const [base, weight = DEFAULT_COLOR_WEIGHT] = (color as string).split('.');
    const backgroundColor =
        productColors[base as keyof ProductColors][parseInt(weight) as keyof ProductColor];
    const hoverColor = tinycolor(backgroundColor).darken(15).toHexString();
    const textColor = tinycolor
        .mostReadable(hoverColor, [defaultColors.white, productColors.gray[900]])
        .toHexString();

    return {
        backgroundColor,
        hoverColor,
        textColor,
    };
};

export const StyledButton = styled.button<ButtonProps>`
    ${getDefaultStyles}
    ${getDisabledStyles}
    ${getDefaultColors}
    ${getButtonContextStyles}
    ${ellipsis}
    ${noSelect}
    ${props => props.variant === 'primary' && getPrimaryColors(props)}
`;
