import { message, Modal } from 'antd';
import { Button } from 'components/button2';
import Axios from 'axios';
import axiosRetry from 'axios-retry';
import Clipboard from 'clipboard';
import { first } from 'lodash';
import moment from 'moment-timezone';
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { LaunchDarklyProvider } from 'providers/LaunchDarklyProvider';
import '../css/App.css';
import 'assets/css/SplashScreen.css';
import Context from './Context';
import Pages from './Pages';
import { ErrorBoundary } from 'assets/js/components/ErrorBoundary';
import PageLoading from './components/PageLoading';
import { AddressType, ContactActivityType, EmailType, PhoneType, Settings } from './modules/Enums';
import { Account, Campaign, User } from 'types/domain';
import { Settings as LuxonSettings } from 'luxon';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import 'filepond/dist/filepond.min.css';
import { AppContext } from 'types/app';
import { DevLogin } from 'components/dev-login/DevLogin';

const MOBILE_BREAKPOINT = 768;

let IS_TOKEN_AUTH =
    process.env.NODE_ENV === 'development' || process.env.REACT_APP_VERCEL_ENV === 'preview';

window.Enums = {
    Settings,
    EmailType,
    PhoneType,
    AddressType,
    ContactActivityType,
};

window.services = {
    chat: () => {
        if (window.Intercom) {
            return window.Intercom('show');
        }
    },
};

class App extends React.Component<{}, AppContext> {
    private getGrowSurfReadyInterval: NodeJS.Timer | null;

    constructor(props: { getSegmentReadyInterval: null }) {
        super(props);

        // Production: Locked to a specific apiUrl
        let apiUrl = process.env.REACT_APP_API_URL;

        // Review Apps
        if (
            !apiUrl || // legacy local dev builds
            window.location.host.endsWith('.legendairy.xyz') || // Proxied from review app
            window.location.host === 'givebutter.test' // Proxied from local dev
        ) {
            apiUrl = window.location.protocol + '//' + window.location.host;
            IS_TOKEN_AUTH = false;
        }

        // Staging
        if (window.location.host === 'dashboard.churnbutter.xyz') {
            apiUrl = 'https://churnbutter.xyz';
            IS_TOKEN_AUTH = false;
        }

        this.state = {
            loading: true,
            apiUrl: IS_TOKEN_AUTH
                ? window.localStorage.getItem('lastUsedApiUrl') ?? apiUrl
                : apiUrl,
            vercelApiUrls: [],
            devToken: window.localStorage.getItem('devToken') ?? undefined,
            accounts: [],
            currentUser: undefined as unknown as User,
            currentAccount: undefined as unknown as Account,
            previousAccount: undefined,
            previousLocation: undefined,
            collapsed: false,
            showReferralButton: false,
            showConnectionErrorModal: false,
            currentCampaignViewing: {} as Campaign,
            isMobile: document.body.clientWidth <= MOBILE_BREAKPOINT,
            sidebarCollapsed: document.body.clientWidth <= MOBILE_BREAKPOINT,

            refreshUserData: this.setUserData,

            setCurrentUser: (currentUser: User) => {
                this.setState({
                    currentUser,
                });
            },

            setCurrentCampaignViewing: (currentCampaignViewing: Campaign) => {
                this.setState({
                    currentCampaignViewing,
                });
            },

            setCurrentAccount: (currentAccount: Account) => {
                this.setState({
                    currentAccount,
                });
            },

            setPreviousAccount: (previousAccount: Account) => {
                this.setState({
                    previousAccount,
                });
            },

            setPreviousLocation: (previousLocation: string) => {
                this.setState({
                    previousLocation,
                });
            },

            setSidebarCollapsed: (value: boolean) => {
                this.setState({
                    sidebarCollapsed: value,
                });
            },

            setCollapsed: (collapsed: string | boolean | null) => {
                this.setState({
                    collapsed,
                });
            },

            logout: () => {
                if (IS_TOKEN_AUTH) {
                    window.localStorage.removeItem('devToken');

                    if (window.location.pathname !== '/') {
                        window.location.href = window.location.origin;
                    }

                    return;
                }

                return Axios.post(`${this.state.apiUrl}/logout`)
                    .then(() => {
                        window.location.href = `${this.state.apiUrl}/login`;
                    })
                    .catch(() => {
                        message.error(
                            <React.Fragment>
                                <span className={'font-semibold'}>Whoops.</span> Something went
                                wrong. Please try again.
                            </React.Fragment>
                        );
                    });
            },
        };

        this.getGrowSurfReadyInterval = null;
    }

    handleResize = ({ target }: any) => {
        if (target.innerWidth <= 1100 && !this.state.isMobile) {
            this.setState({
                isMobile: true,
                sidebarCollapsed: true,
            });
        } else if (target.innerWidth > 1100 && this.state.isMobile) {
            this.setState({
                isMobile: false,
                sidebarCollapsed: false,
            });
        }
    };

    async componentDidMount() {
        new Clipboard('.clipboard');

        window.addEventListener('resize', this.handleResize);

        const propagateUserData = () => {
            this.setUserData()
                .then(() => {
                    this.getSegmentReady();
                    this.getCapterraReady();
                })
                .catch(() => {
                    console.info('Invalid user data, redirecting to profile...');
                    // if we have a session but no accounts
                    window.location.replace(`${this.state.apiUrl}/profile`);
                });
        };

        // Attempt to read an edge config api url if it exists
        if ((!this.state.devToken || this.state.apiUrl.length === 0) && IS_TOKEN_AUTH) {
            const {
                data: { url, apiUrls },
            } = await Axios.get<{ url?: string; apiUrls?: AppContext['vercelApiUrls'] }>(
                '/api/fetchApiUrl'
            );

            const apiUrl = url ? localStorage.getItem('lastUsedApiUrl') ?? url : this.state.apiUrl;
            localStorage.setItem('lastUsedApiUrl', apiUrl);

            return this.setState(
                {
                    apiUrl,
                    vercelApiUrls: apiUrls ?? [],
                },
                () => propagateUserData()
            );
        }

        propagateUserData();
    }

    getSegmentReady() {
        if (window.analytics && window.analytics.initialize) {
            if (this.state.currentUser) {
                window.analytics.identify(
                    this.state.currentUser.uid,
                    this.state.currentUser.segment_object,
                    {
                        Intercom: {
                            user_hash: this.state.currentUser.intercom_hash,
                        },
                    }
                );
            }
        }
    }

    getCapterraReady() {
        const { currentUser } = this.state;
        const { REACT_APP_CAPTERRA_ID, REACT_APP_CAPTERRA_KEY } = process.env;

        if (
            currentUser &&
            REACT_APP_CAPTERRA_ID &&
            REACT_APP_CAPTERRA_KEY &&
            moment(currentUser.created_at).isAfter(moment().subtract(30, 'days'))
        ) {
            const script = document.createElement('script');

            script.async = true;
            script.src = `https://ct.capterra.com/capterra_tracker.js?vid=${REACT_APP_CAPTERRA_ID}&vkey=${REACT_APP_CAPTERRA_KEY}`;

            document.body.appendChild(script);
        }
    }

    getBestCurrentAccount(accounts: Account[]) {
        return new Promise<Account>((resolve, reject) => {
            const accountIdRegex = /accounts\/(?<accountId>[^/]+)/g;
            const foundAccountId = accountIdRegex.exec(window.location.pathname);
            const accountIdFromPathname = !!foundAccountId ? foundAccountId[1] : undefined;
            const matchingAccount = accounts.find(
                account => accountIdFromPathname === account.id.toString()
            );
            const firstAccount = accounts && accounts.length > 0 && first(accounts);

            if (matchingAccount) {
                resolve(matchingAccount);
            } else if (accountIdFromPathname) {
                // This may be set if the user has switched to a chapter account
                Axios.get(`/ajax/access?type=account&id=${accountIdFromPathname}`)
                    .then(response => {
                        resolve(response.data);
                    })
                    .catch(() => {
                        if (!!firstAccount) {
                            resolve(firstAccount);
                        } else {
                            reject('No account access and no accounts');
                        }
                    });
            } else if (!!firstAccount) {
                resolve(firstAccount);
            } else {
                reject('No account found');
            }
        });
    }

    setUserData = () =>
        new Promise<void>((resolve, reject) => {
            const { apiUrl, devToken } = this.state;

            Axios.defaults.baseURL = `${apiUrl}/api`;
            Axios.defaults.withCredentials = true;

            if (devToken) {
                Axios.defaults.headers.common['Authorization'] = `Bearer ${devToken}`;
            }

            this.setAxiosInterceptors();

            this.getCsrfToken()
                .then(() => {
                    Axios.get('/user')
                        .then(response => {
                            const {
                                growsurf_hash,
                                intercom_hash,
                                launchdarkly_hash,
                                segment_object,
                                menu: accounts,
                                data: currentUser,
                            } = response.data;

                            currentUser.growsurf_hash = growsurf_hash;
                            currentUser.intercom_hash = intercom_hash;
                            currentUser.launchdarkly_hash = launchdarkly_hash;
                            currentUser.segment_object = segment_object;

                            this.getBestCurrentAccount(accounts)
                                .then((currentAccount: Account) => {
                                    if (currentAccount.timezone) {
                                        moment.tz.setDefault(currentAccount.timezone);
                                        LuxonSettings.defaultZone = currentAccount.timezone;
                                    }

                                    this.setState(
                                        {
                                            currentAccount,
                                            accounts,
                                            currentUser,
                                            loading: false,
                                        },
                                        resolve
                                    );
                                })
                                .catch(e => {
                                    reject(e);
                                });
                        })
                        .catch(() => null);
                })
                .catch(() => null);
        });

    getCsrfToken() {
        const { apiUrl } = this.state;

        if (IS_TOKEN_AUTH) {
            return Promise.resolve();
        }

        return Axios.get<string>(`${apiUrl}/sanctum/csrf-cookie`).then(response => {
            this.setState({
                csrfToken: response.config.headers['X-XSRF-TOKEN'],
            });

            return Promise.resolve();
        });
    }

    setAxiosInterceptors() {
        axiosRetry(Axios, {
            retries: 1,

            retryCondition: async error => {
                if (Object.keys(error.response?.data?.errors || []).includes('csrf')) {
                    try {
                        await this.getCsrfToken();
                    } catch (e) {
                        this.setState({
                            showConnectionErrorModal: true,
                        });
                    }
                }

                return false;
            },

            retryDelay: retryCount => retryCount * 2000,
        });

        Axios.interceptors.response.use(
            response => response,
            error => {
                if (error?.response?.status === 401) {
                    return this.state.logout();
                }

                return Promise.reject(error);
            }
        );
    }

    renderErrorModal() {
        return (
            <Modal footer={[]} visible={true} closable={false} centered>
                <p style={{ color: '#888', fontWeight: '600', marginBottom: '0px' }}>Dashboard</p>

                <h2 style={{ fontWeight: '600', lineHeight: '90%', marginBottom: '15px' }}>
                    Login Expired
                </h2>

                <p style={{ color: '#000', marginBottom: '15px' }}>
                    For security reasons, you've been logged out. Click the button below to log back
                    in. If this error persists, please{' '}
                    <a href="https://givebutter.com/support">contact us</a>.
                </p>

                <Button onClick={this.state.logout}>Login</Button>
            </Modal>
        );
    }

    render() {
        const {
            loading,
            showConnectionErrorModal,
            currentAccount,
            devToken,
            apiUrl,
            vercelApiUrls,
        } = this.state;

        if (IS_TOKEN_AUTH && !devToken) {
            return (
                <DevLogin
                    apiUrl={apiUrl}
                    urls={vercelApiUrls}
                    onLoginSuccess={(token: string) => {
                        this.setState({ devToken: token }, () => this.setUserData());
                    }}
                    onSelectVercelUrl={(apiUrl: string) => {
                        this.setState({ apiUrl });
                    }}
                />
            );
        }

        let routerBasename = '';
        if (!window.location.host.startsWith('dashboard.')) {
            routerBasename = '/dashboard';
        }

        return (
            <ErrorBoundary>
                <Context.Provider value={this.state as any}>
                    {showConnectionErrorModal && this.renderErrorModal()}
                    {!showConnectionErrorModal && loading && <PageLoading />}
                    {!showConnectionErrorModal && !loading && (
                        <LaunchDarklyProvider>
                            <Router basename={routerBasename}>
                                <div
                                    id={
                                        currentAccount &&
                                        currentAccount.classification &&
                                        `is-${currentAccount.classification}`
                                    }
                                >
                                    <Pages />
                                </div>
                            </Router>
                        </LaunchDarklyProvider>
                    )}
                </Context.Provider>
            </ErrorBoundary>
        );
    }
}

export default App;
