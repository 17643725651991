import React, { ReactNode, useEffect } from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { useScrollTopManager } from 'contexts/useScrollTopManager';

interface ScrollTopProps {
    children?: ReactNode;
}

const ScrollTop = (props: ScrollTopProps) => {
    const { children } = props;
    const { pathname } = useLocation();
    const { exclude } = useScrollTopManager();

    const match = useRouteMatch(exclude);

    useEffect(() => {
        if (!match) {
            window.scrollTo(0, 0);
        }
    }, [match, pathname]);

    return <>{children}</>;
};

export { ScrollTop };
