import * as Tooltip from '@radix-ui/react-tooltip';
import { ComponentProps, ForwardedRef, forwardRef } from 'react';
import * as Styled from './styled';

const TooltipProvider = Tooltip.Provider;
const TooltipPortal = Tooltip.Portal;
const TooltipRoot = Tooltip.Root;
const TooltipTrigger = Tooltip.Trigger;
const TooltipContent = forwardRef(
    (props: ComponentProps<typeof Styled.TooltipContent>, ref: ForwardedRef<HTMLDivElement>) => {
        const { className = '', ...rest } = props;

        return <Styled.TooltipContent className={className} ref={ref} {...rest} />;
    }
);
const TooltipArrow = Styled.TooltipArrow;

const Provider = TooltipProvider;
const Portal = TooltipPortal;
const Root = TooltipRoot;
const Trigger = TooltipTrigger;
const Content = TooltipContent;
const Arrow = TooltipArrow;

export {
    TooltipProvider,
    TooltipPortal,
    TooltipRoot,
    TooltipTrigger,
    TooltipContent,
    TooltipArrow,
    //
    Provider,
    Portal,
    Root,
    Trigger,
    Content,
    Arrow,
};
