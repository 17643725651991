import { InlineError, InlineErrorProps } from 'components/filter/InlineError';
import { useGivebutterFormContext } from 'components/form/useGivebutterFormContext';
import { get } from 'lodash';
import React, { ComponentPropsWithoutRef, createContext, useContext } from 'react';
import { FieldError, FieldPath, FieldValues } from 'react-hook-form';

interface UseFormHelpersErrorProps<T extends FieldValues, K extends FieldPath<T>>
    extends InlineErrorProps {
    name: K;
    children?: React.ReactNode;
}

interface UseFormErrorMessageProps extends ComponentPropsWithoutRef<'p'> {}
interface UseFormErrorValidProps {
    children: React.ReactNode;
}

const UseFormErrorContext = createContext<FieldError | undefined>(undefined);

const UseFormError = <T extends FieldValues, K extends FieldPath<T>>(
    props: UseFormHelpersErrorProps<T, K>
) => {
    const { name, children, ...rest } = props;
    const error = useFormError<T, K>(name);

    if (!error && !children) {
        return null;
    }

    if (!children) {
        return <InlineError message={error?.message} {...rest} />;
    }

    return (
        <>
            <UseFormErrorContext.Provider value={error}>{children}</UseFormErrorContext.Provider>
        </>
    );
};

const UseFormErrorMessage = (props: UseFormErrorMessageProps) => {
    const error = useContext(UseFormErrorContext);

    if (!error) {
        return null;
    }

    return <InlineError message={error?.message} {...props} />;
};

const UseFormErrorValid = ({ children }: UseFormErrorValidProps) => {
    const error = useContext(UseFormErrorContext);

    if (error) {
        return null;
    }

    return <>{children}</>;
};

const useFormError = <T extends FieldValues, K extends FieldPath<T>>(name: K) => {
    const {
        formState: { errors },
    } = useGivebutterFormContext<T>();

    const error = get(errors, name);

    return (Array.isArray(error) ? error?.root : error) as FieldError | undefined;
};

const Root = UseFormError;
const Message = UseFormErrorMessage;
const Valid = UseFormErrorValid;

export type { UseFormHelpersErrorProps };
export {
    useFormError,
    //
    UseFormError,
    UseFormErrorMessage,
    UseFormErrorValid,
    UseFormErrorContext,
    //
    Root,
    Message,
    Valid,
};
