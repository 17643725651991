import 'react-app-polyfill/stable';
import 'animate.css/animate.css';
import 'antd/dist/antd.css';
import 'index.css';
import React from 'react';
import ReactDOM from 'react-dom';
import Root from './root';

const rootElement = document.getElementById('root');

if (rootElement?.hasChildNodes()) {
    ReactDOM.hydrate(<Root />, rootElement);
} else {
    ReactDOM.render(<Root />, rootElement);
}
