import React from 'react';
import { AppContext } from 'types/app';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { getAuthToken } from 'api/auth';

interface DevLoginForm {
    email: string;
    password: string;
}

interface DevLoginProps {
    onLoginSuccess: (token: string) => void;
    onSelectVercelUrl: (url: string) => void;
    apiUrl: string;
    urls: AppContext['vercelApiUrls'];
}

const DevLogin = (props: DevLoginProps) => {
    const { onLoginSuccess, apiUrl, onSelectVercelUrl, urls } = props;
    const { handleSubmit, register } = useForm<DevLoginForm>({
        defaultValues: {
            email: '',
            password: '',
        },
    });
    const { mutate: login } = useMutation(
        (formValues: DevLoginForm) => getAuthToken(formValues, apiUrl),
        {
            onSuccess: ({ token }) => {
                window.localStorage.setItem('devToken', token);
                onLoginSuccess(token);
            },
        }
    );

    return (
        <div className={'flex flex-col gap-6 p-8'}>
            {urls.length > 0 && (
                <div className={'border border-gray-500 p-2'}>
                    <label>
                        Select a backend url:
                        <br />
                        <select
                            value={apiUrl}
                            onChange={e => {
                                const url = e.target.value;
                                window.localStorage.setItem('lastUsedApiUrl', url);
                                onSelectVercelUrl(url);
                            }}
                        >
                            {urls.map(({ url, branch }) => {
                                return (
                                    <option value={url} key={`${branch}-${url}`}>
                                        {`Branch: ${branch} <=> URL: ${url}`}
                                    </option>
                                );
                            })}
                        </select>
                    </label>
                </div>
            )}
            <p className={'text-lg'}>
                Current backend url: <span className={'font-semibold'}>{apiUrl}</span>
            </p>
            <form
                className={'flex flex-col gap-y-4'}
                onSubmit={handleSubmit(formValues => {
                    login(formValues);
                })}
            >
                <div className={'flex flex-col gap-y-2'}>
                    <label>Email</label>
                    <input type={'text'} {...register('email')} />
                </div>
                <div className={'flex flex-col gap-y-2'}>
                    <label>Password</label>
                    <input type={'password'} {...register('password')} />
                </div>
                <button type={'submit'}>Login</button>
            </form>
        </div>
    );
};

export { DevLogin };
