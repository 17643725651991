import * as PopoverPrimitive from '@radix-ui/react-popover';
import * as Styled from 'components/popover/styled';

const PopoverRoot = PopoverPrimitive.Root;
const PopoverPortal = PopoverPrimitive.Portal;
const PopoverTrigger = PopoverPrimitive.Trigger;
const PopoverAnchor = PopoverPrimitive.Anchor;
const PopoverContent = Styled.Content;
const PopoverClose = PopoverPrimitive.Close;
const PopoverArrow = PopoverPrimitive.Arrow;
const PopoverContentStyles = Styled.PopoverContentStyles;

const Root = PopoverRoot;
const Portal = PopoverPortal;
const Trigger = PopoverTrigger;
const Anchor = PopoverAnchor;
const Content = PopoverContent;
const Close = PopoverClose;
const Arrow = PopoverArrow;

export {
    PopoverRoot,
    PopoverPortal,
    PopoverTrigger,
    PopoverAnchor,
    PopoverContent,
    PopoverClose,
    PopoverArrow,
    PopoverContentStyles,
    //
    Root,
    Portal,
    Trigger,
    Anchor,
    Content,
    Close,
    Arrow,
};
