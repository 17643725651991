import { createGlobalStyle } from 'styled-components';
import { FocusVisible } from 'components/util';

const GlobalStyle = createGlobalStyle`
    * {
        font-variant-ligatures: none;
        font-variant-numeric: proportional-nums !important;
        font-family: ${props => props.theme.typography.font};
        -webkit-font-smoothing: antialiased;
    }

    .no-scroll {
        overflow: hidden;
    }

    body {
        color: ${props => props.theme.color.onyx};
        margin: 0 auto;
        padding: 0;
        font-weight: ${props => props.theme.typography.body.weight};
        background-color: ${props => props.theme.color.slateLightest};
        letter-spacing: 0px;
    }

    [role='dialog'] {
        font-size: 16px;
    }

    a {
        text-decoration: none;
        font-family: inherit;
        -webkit-transition: ${props => props.theme.utility.transition};
        -o-transition: ${props => props.theme.utility.transition};
        transition: ${props => props.theme.utility.transition};
        font-weight: 600;
        cursor: pointer !important;
        position: relative;
        color: ${p => p.theme.color.cobalt};

        &:hover {
            color: ${p => p.theme.color.cobaltDark};
        }
    }

    strong {
        font-weight: 700 !important;
    }

    .clearfix {
        *zoom: 1;
    }

    .clearfix:before,
    .clearfix:after {
        display: table;
        line-height: 0;
        content: '';
    }

    .clearfix:after {
        clear: both;
    }

    // safari ugly buttons fix
    button,
    html [type='button'],
    [type='reset'],
    [type='submit'],
    &:focus {
        -webkit-appearance: none !important;
        outline: transparent none 0px;
    }

    // antd switch tw fix
    .ant-switch {
        opacity: 0.60;
        background-color: rgba(0, 0, 0, 0.25) !important;
    }
    .ant-switch:hover {
        opacity: 1;
    }
    .ant-switch-checked {
        opacity: 1;
        background-color: ${props => props.theme.color.cobalt} !important;
    }

    // antd toast
    .ant-message {
        z-index: ${props => props.theme.zIndex.Outermost};
    }
    .ant-message-custom-content {
        display: flex;
        align-items: center;
    }

    // antd modal
    .ant-modal-wrap, .ant-modal-mask {
       z-index: ${props => props.theme.zIndex.TinyMce};
    }

    // we use :where to make this less specific than class selectors
    :where(a),
    :where(button),
    :where(*[type='button']) {
        position: relative;

        &:focus-visible {
            ${FocusVisible};
        }
    }

    // tinymce
    .tox, .tox-dialog-wrap, .tox-menu, .tox-collection, .tox-collection--list, .tox-selected-menu {
        z-index: ${props => props.theme.zIndex.TinyMce} !important;
    }

    .tox-toolbar__overflow {
        max-width: 500px !important;
    }
`;

export { GlobalStyle };
