/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { IntercomProductTourTargets } from 'constants/intercom';
import Context from 'assets/js/Context';
import * as CampaignModal from 'components/campaign-modal';
import {
    Label,
    NewCampaignButton,
    SidebarCampaign,
    SidebarCampaigns,
    SidebarCollapseButton,
    SidebarItem,
    SidebarSubItem,
    SidebarSubItems,
    SidebarText,
} from 'components/sidebar/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faHome,
    faBriefcase,
    faEnvelope,
    faBook,
    faChevronDown,
    faGear,
    faMegaphone,
} from '@fortawesome/pro-regular-svg-icons';
import { useGlobalState } from 'customHooks/useGlobalState';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { RenderOrNull } from 'components/util';

export function HomeLink() {
    const context = useContext(Context);
    const location = useLocation();
    const { sidebarCollapsed } = context;

    const handleCloseSidebar = () => {
        if (context.isMobile) {
            context.setSidebarCollapsed(true);
        }
    };

    return (
        <Link className="intercom-target-home" to={`/accounts/${context.currentAccount.id}/home`}>
            <SidebarItem
                collapsed={sidebarCollapsed}
                active={location.pathname.includes(`accounts/${context.currentAccount.id}/home`)}
                onClick={handleCloseSidebar}
            >
                <SidebarText sidebarCollapsed={sidebarCollapsed}>
                    <FontAwesomeIcon icon={faHome} />
                    <span>Home</span>
                </SidebarText>
            </SidebarItem>
        </Link>
    );
}

export function PayoutsLink() {
    const context = useContext(Context);
    const location = useLocation();
    const { sidebarCollapsed } = context;

    const handleCloseSidebar = () => {
        if (context.isMobile) {
            context.setSidebarCollapsed(true);
        }
    };

    return (
        <Link to={`/accounts/${context.currentAccount.id}/payouts`}>
            <SidebarItem
                data-intercom-target={IntercomProductTourTargets.PayoutsLink}
                collapsed={sidebarCollapsed}
                active={location.pathname.includes(`accounts/${context.currentAccount.id}/payouts`)}
                onClick={handleCloseSidebar}
            >
                <SidebarText sidebarCollapsed={sidebarCollapsed}>
                    <FontAwesomeIcon icon={faBriefcase} />
                    <span>Payouts</span>
                </SidebarText>
            </SidebarItem>
        </Link>
    );
}

const getTrackName = (pathname: string) => pathname.split(pathname.charAt(0))[3];
const trackPaths = ['transactions', 'contacts', 'plans', 'tickets', 'chapters'];

const trackTitles = {
    transactions: 'Transactions',
    contacts: 'Contacts',
    plans: 'Recurring Plans',
    tickets: 'Tickets',
    chapters: 'Chapters',
};

export function TrackLink() {
    const menuName = 'track';
    const [isOpen, setIsOpen] = useState(true);

    const context = useContext(Context);
    const { setCollapsed, collapsed, isMobile, currentAccount } = context;
    const location = useLocation();
    const trackName = getTrackName(location.pathname);

    const isActiveTrack = [...trackPaths, 'chapters'].includes(trackName);

    useEffect(() => {
        if (isActiveTrack) {
            setCollapsed(menuName);
        }
    }, [isActiveTrack]);

    useEffect(() => {
        if (context.sidebarCollapsed) {
            setCollapsed(null);
        } else {
            setCollapsed('track');
        }
    }, [context.sidebarCollapsed]);

    const isCollapsed = context.sidebarCollapsed ? collapsed !== menuName : !isOpen;

    const handleCloseSidebar = () => {
        if (isMobile) {
            context.setSidebarCollapsed(true);
        }
    };

    return (
        <>
            <SidebarCollapseButton
                data-intercom-target={IntercomProductTourTargets.TrackLink}
                onClick={() =>
                    context.sidebarCollapsed
                        ? setCollapsed(!isCollapsed ? null : menuName)
                        : setIsOpen(val => !val)
                }
            >
                <SidebarItem collapsed={context.sidebarCollapsed} active={isActiveTrack}>
                    <SidebarText sidebarCollapsed={context.sidebarCollapsed}>
                        <FontAwesomeIcon icon={faBook} />
                        <span>Track</span>
                    </SidebarText>
                    <FontAwesomeIcon
                        className={`${context.sidebarCollapsed ? 'hidden' : ''} ${
                            isOpen ? 'rotate-180' : ''
                        } transition-all`}
                        icon={faChevronDown}
                    />
                </SidebarItem>
            </SidebarCollapseButton>
            <SidebarSubItems sidebarCollapsed={context.sidebarCollapsed} collapsed={isCollapsed}>
                {trackPaths
                    .filter(trackPathsItem => currentAccount.access.pages.includes(trackPathsItem))
                    .map(item => {
                        return (
                            <Link
                                key={item}
                                to={`/accounts/${context.currentAccount.id}/${item}`}
                                className={`intercom-target-${item}`}
                                onClick={handleCloseSidebar}
                            >
                                <SidebarSubItem active={trackName === item}>
                                    {trackTitles[item as keyof typeof trackTitles]}
                                </SidebarSubItem>
                            </Link>
                        );
                    })}
            </SidebarSubItems>
        </>
    );
}

const getAccountSettingsName = (pathname: string) => {
    const result = pathname.split(pathname.charAt(0));
    if (result[3] !== 'settings') return '';
    return result[4];
};

export function AccountSettingsLink() {
    const menuName = 'settings';

    const context = useContext(Context);
    const { setCollapsed, collapsed, isMobile } = context;
    const location = useLocation();
    const pageName = getAccountSettingsName(location.pathname);
    const isActiveSettings = [
        'about',
        'settings',
        'branding',
        'landing',
        'integrations',
        'imports',
        'users',
    ].includes(pageName);
    const [isOpen, setIsOpen] = useState(isActiveSettings);

    const isCollapsed = context.sidebarCollapsed ? collapsed !== menuName : !isOpen;

    const handleCloseSidebar = () => {
        if (isMobile) {
            context.setSidebarCollapsed(true);
        }
    };

    return (
        <>
            <SidebarCollapseButton
                data-intercom-target={IntercomProductTourTargets.AccountSettingsLink}
                onClick={() =>
                    context.sidebarCollapsed
                        ? setCollapsed(!isCollapsed ? null : menuName)
                        : setIsOpen(val => !val)
                }
            >
                <SidebarItem
                    collapsed={context.sidebarCollapsed}
                    active={location.pathname.includes(
                        `/accounts/${context.currentAccount.id}/settings`
                    )}
                >
                    <SidebarText sidebarCollapsed={context.sidebarCollapsed}>
                        <FontAwesomeIcon icon={faGear} />
                        <span>Account</span>
                    </SidebarText>
                    <FontAwesomeIcon
                        className={`${context.sidebarCollapsed ? 'hidden' : ''} ${
                            isOpen ? 'rotate-180' : ''
                        } transition-all`}
                        icon={faChevronDown}
                    />
                </SidebarItem>
            </SidebarCollapseButton>

            <SidebarSubItems collapsed={isCollapsed} sidebarCollapsed={context.sidebarCollapsed}>
                <Link
                    className={'intercom-target-about'}
                    to={`/accounts/${context.currentAccount.id}/settings/about`}
                    onClick={handleCloseSidebar}
                >
                    <SidebarSubItem
                        active={location.pathname.includes(
                            `/accounts/${context.currentAccount.id}/settings/about`
                        )}
                    >
                        About
                    </SidebarSubItem>
                </Link>

                <Link
                    className={'intercom-target-settings'}
                    to={`/accounts/${context.currentAccount.id}/settings/settings`}
                    onClick={handleCloseSidebar}
                >
                    <SidebarSubItem
                        active={location.pathname.includes(
                            `/accounts/${context.currentAccount.id}/settings/settings`
                        )}
                    >
                        Settings
                    </SidebarSubItem>
                </Link>

                {context.currentAccount.access.role === 'admin' && (
                    <Link
                        className={'intercom-target-integrations'}
                        to={`/accounts/${context.currentAccount.id}/settings/integrations`}
                        onClick={handleCloseSidebar}
                    >
                        <SidebarSubItem
                            active={location.pathname.includes(
                                `/accounts/${context.currentAccount.id}/settings/integrations`
                            )}
                        >
                            Integrations
                        </SidebarSubItem>
                    </Link>
                )}

                {context.currentAccount.access.role === 'admin' && (
                    <Link
                        className="intercom-target-users"
                        to={`/accounts/${context.currentAccount.id}/settings/users`}
                        onClick={handleCloseSidebar}
                    >
                        <SidebarSubItem
                            active={location.pathname.includes(
                                `/accounts/${context.currentAccount.id}/settings/users`
                            )}
                        >
                            Users
                        </SidebarSubItem>
                    </Link>
                )}

                {context.currentAccount.access.role === 'admin' && (
                    <Link
                        className="intercom-target-users"
                        to={`/accounts/${context.currentAccount.id}/settings/imports`}
                        onClick={handleCloseSidebar}
                    >
                        <SidebarSubItem
                            active={location.pathname.includes(
                                `/accounts/${context.currentAccount.id}/settings/imports`
                            )}
                            last
                        >
                            Imports
                        </SidebarSubItem>
                    </Link>
                )}
            </SidebarSubItems>
        </>
    );
}

export function EngageLink() {
    const { engageLetters } = useFlags();
    const menuName = 'engage';
    const {
        currentAccount,
        sidebarCollapsed,
        setSidebarCollapsed,
        setCollapsed,
        collapsed,
        isMobile,
    } = useGlobalState();
    const location = useLocation();
    const [isOpen, setIsOpen] = useState(location.pathname.includes('engage'));

    const isCollapsed = sidebarCollapsed ? collapsed !== menuName : !isOpen;

    const handleCloseSidebar = () => {
        if (isMobile) {
            setSidebarCollapsed(true);
        }
    };

    return (
        <>
            <SidebarCollapseButton
                data-intercom-target={IntercomProductTourTargets.EngageLink}
                onClick={() =>
                    sidebarCollapsed
                        ? setCollapsed(!isCollapsed ? null : menuName)
                        : setIsOpen(val => !val)
                }
            >
                <SidebarItem
                    collapsed={sidebarCollapsed}
                    active={location.pathname.includes(`/accounts/${currentAccount.id}/engage`)}
                >
                    <SidebarText sidebarCollapsed={sidebarCollapsed}>
                        <FontAwesomeIcon icon={faEnvelope} />
                        <span>Engage</span>
                    </SidebarText>
                    <FontAwesomeIcon
                        className={`${sidebarCollapsed ? 'hidden' : ''} ${
                            isOpen ? 'rotate-180' : ''
                        } transition-all`}
                        icon={faChevronDown}
                    />
                </SidebarItem>
            </SidebarCollapseButton>

            <SidebarSubItems collapsed={isCollapsed} sidebarCollapsed={sidebarCollapsed}>
                <Link to={`/accounts/${currentAccount.id}/engage`} onClick={handleCloseSidebar}>
                    <SidebarSubItem
                        active={
                            location.pathname.includes(`/accounts/${currentAccount.id}/engage`) &&
                            !location.pathname.includes('letters')
                        }
                    >
                        Email & SMS
                    </SidebarSubItem>
                </Link>
                <RenderOrNull cond={engageLetters}>
                    <Link
                        to={`/accounts/${currentAccount.id}/engage/letters`}
                        onClick={handleCloseSidebar}
                    >
                        <SidebarSubItem
                            active={location.pathname.includes(
                                `/accounts/${currentAccount.id}/engage/letters`
                            )}
                        >
                            Letters
                        </SidebarSubItem>
                    </Link>
                </RenderOrNull>
            </SidebarSubItems>
        </>
    );
}

export function RaiseLink() {
    const menuName = 'raise';
    const context = useContext(Context);
    const { setCollapsed, collapsed, isMobile } = context;
    const location = useLocation();

    const [isOpen, setIsOpen] = useState(true);
    const isCollapsed = context.sidebarCollapsed ? collapsed !== menuName : !isOpen;

    const handleCloseSidebar = () => {
        if (isMobile) {
            context.setSidebarCollapsed(true);
        }
    };

    return (
        <React.Fragment>
            <SidebarCollapseButton
                data-intercom-target={IntercomProductTourTargets.RaiseLink}
                onClick={() =>
                    context.sidebarCollapsed
                        ? setCollapsed(!isCollapsed ? null : menuName)
                        : setIsOpen(val => !val)
                }
            >
                <SidebarItem
                    collapsed={context.sidebarCollapsed}
                    active={
                        location.pathname === `/accounts/${context.currentAccount.id}/campaigns`
                    }
                >
                    <SidebarText sidebarCollapsed={context.sidebarCollapsed}>
                        <FontAwesomeIcon icon={faMegaphone} />
                        <span>Raise</span>
                    </SidebarText>
                    <FontAwesomeIcon
                        className={`${context.sidebarCollapsed ? 'hidden' : ''} ${
                            isOpen ? 'rotate-180' : ''
                        } transition-all`}
                        icon={faChevronDown}
                    />
                </SidebarItem>
            </SidebarCollapseButton>

            <SidebarCampaigns sidebarCollapsed={context.sidebarCollapsed} collapsed={isCollapsed}>
                <Link
                    className="intercom-target-campaigns"
                    to={`/accounts/${context.currentAccount.id}/campaigns`}
                    onClick={handleCloseSidebar}
                >
                    <SidebarSubItem
                        active={
                            location.pathname === `/accounts/${context.currentAccount.id}/campaigns`
                        }
                    >
                        All Campaigns
                    </SidebarSubItem>
                </Link>

                <Label>Recent</Label>

                {context.currentAccount.campaigns.map(item => (
                    <Link
                        key={item.id}
                        to={`/accounts/${context.currentAccount.id}/campaigns/${item.id}/${
                            item.type === 'general' ? 'sharing' : 'details'
                        }`}
                        onClick={handleCloseSidebar}
                    >
                        <SidebarCampaign
                            active={item.active}
                            selected={location.pathname.includes(`campaigns/${item.id}/`)}
                        >
                            {item.title}
                        </SidebarCampaign>
                    </Link>
                ))}

                <CampaignModal.Root>
                    <CampaignModal.Trigger asChild={true}>
                        <NewCampaignButton
                            data-target-intercom={IntercomProductTourTargets.CampaignsLink}
                            className="text-icon"
                        >
                            New Campaign
                        </NewCampaignButton>
                    </CampaignModal.Trigger>
                </CampaignModal.Root>
            </SidebarCampaigns>
        </React.Fragment>
    );
}
