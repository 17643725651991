import create from 'zustand';

interface ScrollTopContext {
    exclude: string[];
    registerExclusion: (paths: string[]) => void;
}

const useScrollTopManager = create<ScrollTopContext>(set => ({
    exclude: [],
    registerExclusion: paths => set(state => ({ exclude: [...state.exclude, ...paths] })),
}));

export { useScrollTopManager };
