import React, { Suspense, lazy } from 'react';
import ReactGA from 'react-ga';
import { Redirect, Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom';
import History from 'history';
import Context from 'assets/js/Context';
import { ScrollTop } from 'assets/js/components/ScrollTop';
import Loader from 'components/loader';
import { Layout } from 'antd';
import Header from 'components/header';

const NotFound = lazy(() => import('./pages/NotFound'));
const Home = lazy(() => import('pages/home/Home'));
const AccountSettings = lazy(() => import('./pages/account-settings'));
const Verification = lazy(() => import('./pages/account-settings/Verification'));
const CampaignSettings = lazy(() => import('./pages/campaign-settings'));
const Campaigns = lazy(() => import('./pages/campaigns/Campaigns'));
const Chapters = lazy(() => import('./pages/chapters/Chapters'));
const ContactsRouter = lazy(() => import('./pages/contacts/ContactsRouter'));
const Payouts = lazy(() => import('./pages/payouts/Payouts'));
const RecurringPayments = lazy(() => import('./pages/recurring-payments/RecurringPayments'));
const Tickets = lazy(() => import('components/pages/tickets/Tickets'));
const EngageRouter = lazy(() => import('assets/js/pages/engage/EngageRouter'));
const TransactionsRouter = lazy(() => import('./pages/transactions/TransactionsRouter'));

interface PagesProps extends RouteComponentProps {}

class Pages extends React.Component<PagesProps> {
    componentDidMount() {
        this.setGoogleAnalytics();
    }

    componentDidUpdate(prevProps: PagesProps) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            window.analytics.page();
        }
    }

    setGoogleAnalytics() {
        const { history, location } = this.props;

        ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID || '');

        const setAnalytics = (location: History.Location<unknown>) => {
            ReactGA.set({
                page: location.pathname,
                title: location.pathname,
            });

            ReactGA.pageview(window.location.pathname + window.location.search);
        };

        history.listen(location => {
            setAnalytics(location);
        });

        setAnalytics(location);
    }

    render() {
        const { sidebarCollapsed, currentAccount } = this.context;
        let className = '';
        if (!currentAccount.can_accept_payments) {
            className += ` notification-padding`;
        }

        if (!sidebarCollapsed) {
            className += ` sidebar-active`;
        }

        return (
            <Layout>
                <Header />
                <Layout.Content className={`content ${className}`}>
                    <ScrollTop>
                        <Suspense fallback={<Loader />}>
                            <Switch>
                                <Route
                                    exact
                                    path={[
                                        '/',
                                        '/accounts/:account_id',
                                        '/accounts/:account_id/home',
                                    ]}
                                >
                                    {this.props.location.pathname === '/' &&
                                    this.context.currentAccount ? (
                                        <Redirect
                                            to={`/accounts/${this.context.currentAccount.id}/home`}
                                        />
                                    ) : (
                                        <Home />
                                    )}
                                </Route>

                                <Route
                                    component={ContactsRouter}
                                    path={'/accounts/:account_id/contacts'}
                                />

                                <Route
                                    exact
                                    component={Verification}
                                    path="/accounts/:account_id/verify"
                                />

                                <Route
                                    exact
                                    component={Campaigns}
                                    path="/accounts/:account_id/campaigns"
                                />

                                <Route
                                    component={CampaignSettings}
                                    path="/accounts/:account_id/campaigns/:campaign_id"
                                />

                                <Route
                                    component={TransactionsRouter}
                                    path="/accounts/:account_id/transactions"
                                />

                                <Route
                                    exact
                                    component={RecurringPayments}
                                    path="/accounts/:account_id/plans"
                                />

                                <Route
                                    exact
                                    path="/accounts/:account_id/tickets"
                                    component={Tickets}
                                />

                                <Route
                                    exact
                                    component={Payouts}
                                    path="/accounts/:account_id/payouts"
                                />

                                <Route
                                    component={AccountSettings}
                                    path="/accounts/:account_id/settings"
                                />

                                <Route
                                    exact
                                    component={Chapters}
                                    path="/accounts/:account_id/chapters"
                                />

                                <Route
                                    component={EngageRouter}
                                    path="/accounts/:account_id/engage"
                                />

                                <Route component={NotFound} />
                            </Switch>
                        </Suspense>
                    </ScrollTop>
                </Layout.Content>
            </Layout>
        );
    }
}

Pages.contextType = Context;

export default withRouter(Pages);
