import Axios from 'axios';

interface LoginParams {
    email: string;
    password: string;
}

const getAuthToken = (loginCredentials: LoginParams, apiUrl = '') =>
    Axios.post(
        '/login',
        {
            ...loginCredentials,
            device_name: 'dev_user',
            platform: 'ios',
        },
        {
            baseURL: `${apiUrl}/api-mobile`,
        }
    ).then(response => {
        return response.data;
    });

export { getAuthToken };
export type { LoginParams };
