import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import styled from 'styled-components';
import { PopoverContentStyles } from 'components/popover';

interface TooltipThemeProps {
    inverse?: boolean;
}

const TooltipContent = styled(TooltipPrimitive.Content)<TooltipThemeProps>`
    ${PopoverContentStyles};
    max-width: 300px;
    padding: 0.5rem 0.75rem;
    background: ${props => (props.inverse ? props.theme.color.onyx : props.theme.color.white)};

    & * {
        color: ${props => (props.inverse ? props.theme.color.white : props.theme.color.onyx)};
    }
`;

const TooltipArrow = styled(TooltipPrimitive.Arrow)<TooltipThemeProps>`
    fill: ${props => (props.inverse ? props.theme.color.onyx : props.theme.color.white)}; ;
`;

export { TooltipContent, TooltipArrow };
