import { createContext } from 'react';
import { AppContext } from 'types/app';
import { Account, User } from 'types/domain';

export default createContext<AppContext>({
    accounts: [],
    currentCampaignViewing: undefined,
    loading: false,
    logout(): void {},
    setCurrentUser(user: User): void {},
    showConnectionErrorModal: false,
    apiUrl: '',
    vercelApiUrls: [],
    isMobile: false,
    currentUser: {} as User,
    currentAccount: {} as Account,
    setCurrentAccount: () => {},
    setPreviousAccount: () => {},
    sidebarCollapsed: false,
    setSidebarCollapsed(collapsed: boolean): void {},
    collapsed: null,
    setCollapsed(collapsed: string | boolean | null): void {},
    setCurrentCampaignViewing: () => {},
    refreshUserData: () => Promise.resolve(),
    setPreviousLocation: () => {},
    showReferralButton: false,
});
