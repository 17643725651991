import React from 'react';

interface LogoProps {
    color?: string;
    height?: string;
}
const Logo = (props: LogoProps) => {
    return (
        <svg
            fill={props.color || 'white'}
            height={props.height || '20px'}
            x="0px"
            y="0px"
            viewBox="0 0 201.6 30"
        >
            <g>
                <path
                    d="M58,7.8c-1.6,0-2.4,1-2.8,2.1l-4.7,12.6L45.9,9.9c-0.4-1.2-1.3-2.2-3-2.2c-1.6,0-3,1.4-3,2.8
                    c0,0.6,0.2,1.1,0.3,1.5l6.6,15.2c0.7,1.6,1.8,2.7,3.4,2.7h0.3c1.6,0,2.8-1.1,3.4-2.7l6.6-15.2c0.1-0.3,0.3-0.9,0.3-1.4
                    C60.9,9,59.6,7.8,58,7.8z"
                />
                <path
                    d="M23.7,13.4h-7.1c-1.5,0-2.7,1.2-2.7,2.7c0,1.5,1.2,2.6,2.7,2.6h4.2v4c-1.6,1.1-3.6,1.7-5.8,1.7
                    c-5,0-8.5-3.8-8.5-8.9v-0.1c0-4.8,3.6-8.7,8.1-8.7c2.6,0,4.5,0.7,6.1,1.9c0.4,0.3,1,0.6,1.9,0.6c1.7,0,3-1.4,3-3
                    c0-1.2-0.7-2-1.3-2.5C21.8,1.9,19,1,14.7,1C6.2,1,0,7.5,0,15.5v0.1C0,23.9,6,30,14.8,30c4.2,0,7.5-1.4,9.8-2.8
                    c1.5-0.9,2.2-2.1,2.2-3.9v-6.8C26.7,14.7,25.4,13.4,23.7,13.4z"
                />
                <path d="M34,0c-1.9,0-3.4,1.1-3.4,2.8V3c0,1.8,1.5,2.9,3.4,2.9c1.9,0,3.4-1.1,3.4-2.9V2.9C37.4,1.1,35.9,0,34,0z" />
                <path
                    d="M72.4,7.6C72.4,7.6,72.4,7.6,72.4,7.6c-6.3,0-10.6,5.1-10.6,11.2v0.1c0,6.3,4.4,10.8,10.6,11.1
                    c0.2,0,0.4,0,0.7,0c3.1,0,5.6-1,7.5-2.6c0.4-0.4,0.8-1,0.8-1.8c0-1.4-1-2.4-2.4-2.4c-0.6,0-1,0.2-1.5,0.5c-1.2,0.9-2.6,1.4-4.3,1.4
                    c-0.3,0-0.5,0-0.7,0c-2.3-0.2-4-1.7-4.5-4.2h4.5h7.6c1.6,0,2.8-1.2,2.8-2.9C82.8,13.5,79.6,7.6,72.4,7.6z M72.4,17.1h-4.6
                    c0.5-2.7,2.1-4.5,4.6-4.6c0,0,0,0,0.1,0c2.6,0,4.2,1.8,4.5,4.6L72.4,17.1L72.4,17.1z"
                />
                <path d="M34,7.8c-1.7,0-3,1.4-3,3v15.9c0,1.7,1.4,3.1,3,3.1c1.7,0,3-1.4,3-3.1V10.8C37,9.1,35.7,7.8,34,7.8z" />
                <path
                    d="M198.6,7.8c-1.8,0-3.2,1.9-4.1,4v-1c0-1.7-1.4-3-3-3c-1.7,0-3,1.4-3,3v15.9c0,1.7,1.4,3.1,3,3.1
                    c1.7,0,3-1.4,3-3.1V21c0-4.3,1.7-6.7,4.8-7.4c1.2-0.3,2.3-1.2,2.3-2.8C201.6,9,200.5,7.8,198.6,7.8z"
                />
                <path
                    d="M174.8,7.6C174.8,7.6,174.8,7.6,174.8,7.6c-6.3,0-10.6,5.1-10.6,11.2v0.1c0,6.3,4.4,10.8,10.6,11.1
                    c0.2,0,0.4,0,0.7,0c3.1,0,5.6-1,7.5-2.6c0.4-0.4,0.8-1,0.8-1.8c0-1.4-1-2.4-2.4-2.4c-0.6,0-1,0.2-1.5,0.5c-1.2,0.9-2.6,1.4-4.3,1.4
                    c-0.3,0-0.5,0-0.7,0c-2.3-0.2-4-1.7-4.5-4.2h4.5h7.6c1.6,0,2.8-1.2,2.8-2.9C185.1,13.5,182,7.6,174.8,7.6z M174.7,17.1h-4.6
                    c0.5-2.7,2.1-4.5,4.6-4.6c0,0,0,0,0.1,0c2.6,0,4.2,1.8,4.5,4.6L174.7,17.1L174.7,17.1z"
                />
                <path
                    d="M160.3,13.2c1.4,0,2.6-1.2,2.6-2.6c0-1.4-1.2-2.6-2.6-2.6h-2.7V5.3c0-1.7-1.4-3-3-3c-1.7,0-3,1.4-3,3V8h-0.2
                    c-1.4,0-2.6,1.2-2.6,2.6c0,1.4,1.2,2.6,2.6,2.6h0.2v10.2c0,5,2.5,6.5,6.3,6.5c1.3,0,2.4-0.2,3.5-0.6c0.8-0.3,1.6-1.2,1.6-2.3
                    c0-1.4-1.2-2.5-2.5-2.5c-0.1,0-0.5,0-0.7,0c-1.4,0-2-0.7-2-2.1v-9.2L160.3,13.2L160.3,13.2z"
                />
                <path
                    d="M128.2,7.8c-1.7,0-3,1.4-3,3V20c0,2.9-1.5,4.4-3.8,4.4c-2.3,0-3.7-1.5-3.7-4.4v-9.2c0-1.7-1.4-3-3-3
                    c-1.7,0-3,1.4-3,3v11.1c0,4.9,2.7,8,7.3,8c3.1,0,4.9-1.7,6.3-3.5v0.2c0,1.7,1.4,3.1,3,3.1c1.7,0,3-1.4,3-3.1V10.8
                    C131.2,9.1,129.9,7.8,128.2,7.8z"
                />
                <path
                    d="M98.8,7.6c-0.5,0-1.1,0-1.5,0.1c-2.3,0.4-3.9,1.7-5.2,3.4V3c0-1.7-1.4-3-3-3c-1.7,0-3,1.4-3,3v23.7
                    c0,1.7,1.4,3.1,3,3.1c1.7,0,3-1.4,3-3v0c1.2,1.4,2.8,2.7,5.2,3.1c0.5,0.1,1,0.1,1.5,0.1c5.1,0,9.8-3.9,9.8-11.1v-0.1
                    C108.6,11.5,103.8,7.6,98.8,7.6z M102.5,18.8c0,3.6-2.4,5.9-5.2,5.9l0,0c-2.8,0-5.2-2.4-5.2-5.9v-0.1c0-3.6,2.4-5.9,5.2-5.9l0,0
                    C100.1,12.8,102.5,15.2,102.5,18.8L102.5,18.8z"
                />
                <path
                    d="M145.1,13.2c1.4,0,2.6-1.2,2.6-2.6c0-1.4-1.2-2.6-2.6-2.6h-2.7V5.3c0-1.7-1.4-3-3-3c-1.7,0-3,1.4-3,3V8h-0.2
                    c-1.4,0-2.6,1.2-2.6,2.6c0,1.4,1.2,2.6,2.6,2.6h0.2v10.2c0,5,2.5,6.5,6.3,6.5c1.3,0,2.4-0.2,3.5-0.6c0.8-0.3,1.6-1.2,1.6-2.3
                    c0-1.4-1.2-2.5-2.5-2.5c-0.1,0-0.5,0-0.7,0c-1.4,0-2-0.7-2-2.1v-9.2L145.1,13.2L145.1,13.2z"
                />
            </g>
        </svg>
    );
};

export default Logo;
