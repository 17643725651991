import { Dropdown, Menu } from 'antd';
import { truncate } from 'lodash';
import React from 'react';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import 'assets/css/Sidebar.css';
import Context from 'assets/js/Context';
import Logo from 'assets/js/components/Logo';
import {
    AccountSettingsLink,
    EngageLink,
    HomeLink,
    PayoutsLink,
    RaiseLink,
    TrackLink,
} from 'components/sidebar/SidebarLinks';
import {
    AccountLogo,
    AccountName,
    CollapseButton,
    CollapsedContainer,
    LogoContainer,
    SidebarContainer,
    SidebarHeader,
    SidebarItemsContainer,
    TopContainer,
} from 'components/sidebar/styled';
import { Account } from 'types/domain';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AppContext } from 'types/app';
import { faChevronDown, faBars } from '@fortawesome/pro-regular-svg-icons';

interface SidebarProps extends RouteComponentProps {
    onboarding: boolean;
}

class Sidebar extends React.Component<SidebarProps> {
    state = {
        isAccountDropdownOpen: false,
    };

    onClickToggleSidebar() {
        this.context.setSidebarCollapsed(!this.context.sidebarCollapsed);
    }
    onClickOverlayLink(e: React.MouseEvent<HTMLAnchorElement>, item: Account) {
        this.context.setCurrentAccount(item);
    }

    renderPrimaryLinks = () => {
        const { currentAccount } = this.context;

        if (currentAccount.access && currentAccount.access.pages) {
            return currentAccount.access.pages.map((page: string) => {
                if (page === 'home') {
                    return <HomeLink key={page} />;
                } else if (page === 'settings') {
                    return <AccountSettingsLink key={page} />;
                } else if (page === 'campaigns') {
                    return <RaiseLink key={page} />;
                } else if (page === 'contacts') {
                    return <React.Fragment key="empty1" />;
                } else if (page === 'chapters') {
                    return <React.Fragment key="empty2" />;
                } else if (page === 'payouts') {
                    return <PayoutsLink key={page} />;
                } else if (page === 'transactions') {
                    return <React.Fragment key="empty3" />;
                } else if (page === 'plans') {
                    return <React.Fragment key="empty4" />;
                } else if (page === 'integrations') {
                    return <React.Fragment key="empty5" />;
                } else if (page === 'tickets') {
                    return (
                        <React.Fragment key="tickets">
                            <TrackLink key="tickets" />
                            <EngageLink key="engage" />
                        </React.Fragment>
                    );
                } else if (page === 'engage') {
                    return <React.Fragment key="empty6" />;
                }

                return null;
            });
        }

        return null;
    };

    renderOverlayLinks() {
        return (
            <Menu className="account-menu">
                {this.context.accounts.map((item: Account) => (
                    <Menu.Item className="account-item" key={item.id}>
                        <Link
                            to={`/accounts/${item.id}/home`}
                            onClick={e => this.onClickOverlayLink(e, item)}
                        >
                            {item.logo && (
                                <div className="logo">
                                    <img src={item.logo} alt="Givebutter Logo" />
                                </div>
                            )}
                            <div className="p">
                                <p className={'truncate'}>{item.title}</p>
                            </div>
                        </Link>
                    </Menu.Item>
                ))}
                <Menu.Item className="account-item">
                    <a href={`${this.context.apiUrl + '/signup/new'}`}>
                        <div className="account-plus">
                            <p>+</p>
                        </div>
                        <div className="p">
                            <p>Create new account</p>
                        </div>
                    </a>
                </Menu.Item>
            </Menu>
        );
    }

    render() {
        const { currentAccount, previousAccount, sidebarCollapsed, isMobile }: AppContext =
            this.context;

        return (
            <React.Fragment>
                {isMobile && (
                    <CollapsedContainer
                        collapsed={sidebarCollapsed}
                        style={{ position: 'absolute', top: 0, left: 0 }}
                    >
                        <CollapseButton onClick={this.onClickToggleSidebar.bind(this)}>
                            <FontAwesomeIcon icon={faBars} />
                        </CollapseButton>
                    </CollapsedContainer>
                )}
                <SidebarContainer
                    notification={
                        previousAccount ||
                        (!this.props.onboarding && !currentAccount.can_accept_payments)
                    }
                    collapsed={sidebarCollapsed}
                >
                    <div style={{ width: '100%' }}>
                        <TopContainer collapsed={sidebarCollapsed}>
                            <LogoContainer collapsed={sidebarCollapsed}>
                                {!sidebarCollapsed && <Logo height="18px" color="#FEBF10" />}
                                <CollapseButton onClick={this.onClickToggleSidebar.bind(this)}>
                                    <FontAwesomeIcon icon={faBars} />
                                </CollapseButton>
                            </LogoContainer>
                        </TopContainer>
                        <Dropdown
                            trigger={['click']}
                            overlay={this.renderOverlayLinks.bind(this)}
                            onVisibleChange={visible => {
                                this.setState({ isAccountDropdownOpen: visible });
                            }}
                        >
                            <SidebarHeader
                                sidebarCollapsed={sidebarCollapsed}
                                className="intercom-target-account-switcher"
                            >
                                {currentAccount.logo && (
                                    <AccountLogo sidebarCollapsed={sidebarCollapsed}>
                                        <img src={currentAccount.logo} alt={currentAccount.title} />
                                    </AccountLogo>
                                )}
                                <AccountName sidebarCollapsed={sidebarCollapsed}>
                                    {truncate(currentAccount.title, { length: 35 })}
                                    <FontAwesomeIcon
                                        className={`${
                                            this.state.isAccountDropdownOpen ? 'rotate-180' : ''
                                        } transition-all`}
                                        icon={faChevronDown}
                                    />
                                </AccountName>
                            </SidebarHeader>
                        </Dropdown>

                        <div style={{ height: 'auto' }} onClick={() => null}>
                            <SidebarItemsContainer sidebarCollapsed={sidebarCollapsed}>
                                <div>{this.renderPrimaryLinks()}</div>
                            </SidebarItemsContainer>
                        </div>
                    </div>
                </SidebarContainer>
            </React.Fragment>
        );
    }
}

Sidebar.contextType = Context;

export default withRouter(Sidebar);
