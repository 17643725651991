import { FieldValues, useFormContext } from 'react-hook-form';
import { UseGivebutterFormReturn } from 'components/form/useGivebutterForm';

const useGivebutterFormContext = <T extends FieldValues>(allowNull = false) => {
    const context = useFormContext<T>() as UseGivebutterFormReturn<T>;

    if (!allowNull && !context) {
        throw new Error(
            'Form provider not found, wrap a FormProvider around your form components that need context.'
        );
    }

    return context;
};

export { useGivebutterFormContext };
