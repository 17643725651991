import React from 'react';
import { useIntl } from 'react-intl';

type Locale = 'en';

const useCurrencySymbol = () => {
    const { locale, formatNumberToParts } = useIntl();

    return formatNumberToParts(1, {
        style: 'currency',
        currencyDisplay: 'symbol',
        currency: getCurrencyBasedOnLocale(locale as Locale),
    }).filter(p => p.type === 'currency')[0]?.value;
};

const CurrencySymbol = () => {
    const symbol = useCurrencySymbol();
    return <>{symbol}</>;
};

const getCurrencyBasedOnLocale = (locale: Locale) => {
    switch (locale) {
        case 'en':
        default: {
            return 'USD';
        }
    }
};

export { useCurrencySymbol, CurrencySymbol };
