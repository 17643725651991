import { ComponentPropsWithoutRef, ForwardedRef, forwardRef } from 'react';
import * as Styled from 'components/overlay/styled';

interface OverlayProps extends ComponentPropsWithoutRef<'div'> {}

const Overlay = forwardRef((props: OverlayProps, ref: ForwardedRef<HTMLDivElement>) => {
    const { children, className = '', ...rest } = props;
    return (
        <>
            <Styled.GlobalStyle />
            <Styled.Overlay ref={ref} className={`animate-opacity ${className}`} {...rest}>
                {children}
            </Styled.Overlay>
        </>
    );
});

const Root = Overlay;

export {
    Overlay,
    //
    Root,
};
