import styled from 'styled-components';

export const Form = styled.form<{ sidebarCollapsed: boolean }>`
    margin-left: ${({ sidebarCollapsed }) => (sidebarCollapsed ? '50px' : '300px')};
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: space-between;
    height: 100%;

    > div {
        margin-bottom: 0;
        height: 90%;

        > label {
            input {
                font-size: 18px;
                height: 100%;
                border: none;
                outline: none;

                &:focus {
                    border: none;
                    outline: none;
                    box-shadow: none;
                }
            }
        }
    }
`;
