import React from 'react';
import { IntlProvider } from 'react-intl';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import App from 'assets/js/App';
import StateProvider from 'customHooks/stateContext/provider';
import { GlobalStyle } from 'helpers/global-style';
import { ThemeProvider } from 'providers/ThemeProvider';
import { TooltipProvider } from 'components/tooltip';
import { queryClient } from 'queries/queryClient';

const Root = ({ children = <App /> }) => {
    return (
        <IntlProvider locale="en" defaultLocale="en">
            <ThemeProvider>
                <GlobalStyle />
                <QueryClientProvider client={queryClient}>
                    <TooltipProvider delayDuration={0}>
                        <StateProvider>{children}</StateProvider>
                        <ReactQueryDevtools initialIsOpen={false} />
                    </TooltipProvider>
                </QueryClientProvider>
            </ThemeProvider>
        </IntlProvider>
    );
};

export default Root;
