import React, { ComponentPropsWithoutRef, Ref } from 'react';
import * as Styled from 'components/text/styled';

export interface TextProps extends Omit<ComponentPropsWithoutRef<'p'>, 'size'> {
    weight?: 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900;
    fontHeight?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'xxxl' | 'huge';
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'xxxl' | 'huge';
    tag?: 'p' | 'span' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'div';
    type?:
        | 'display1'
        | 'display2'
        | 'display3'
        | 'bodyCopy'
        | 'bodyLink'
        | 'label'
        | 'error'
        | 'description'
        | 'default';
}

const Text = React.forwardRef(
    ({ type = 'default', tag = 'p', ...rest }: TextProps, ref: Ref<HTMLInputElement>) => (
        <Styled.Text ref={ref} as={tag} type={type} {...rest} />
    )
);

export default Text;
