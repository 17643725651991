import React from 'react';

export const initialState = {
    plans: {
        search: '',
        filters: {
            isOpen: true,
            filters: {
                amount: [],
                status: [],
                funds: [],
                method: [],
                frequency: [],
                start_at: [],
                next_bill_date: [],
            },
        },
    },
    contacts: {
        search: '',
        filters: {
            isOpen: true,
            filters: {
                is: [],
                total_contributions: [],
                recurring_contributions: [],
                dob: [],
                createdAt: [],
                tags: [],
            },
        },
    },
    transactions: {
        search: '',
        filters: {
            isOpen: true,
            filters: {
                is: [],
                funds: [],
                amount: [],
                method: [],
                status: [],
                createdAt: [],
            },
        },
    },
    tickets: {
        search: '',
        filters: {
            isOpen: true,
            filters: {
                createdAt: [],
                checkedIn: [],
            },
        },
    },
    chapters: {
        search: '',
    },
    campaigns: {
        search: '',
        filters: {
            isOpen: true,
            filters: {
                donors: [],
                raised: [],
                goal: [],
                type_friendly: [],
            },
        },
    },
};

type CurrentPage = keyof typeof initialState | null;

export type StateContext = {
    state: typeof initialState;
    currentPage: CurrentPage;
    setCurrentPage: (page: CurrentPage) => void;
    updateSearch: Function;
    updateFilters: Function;
};

const MyContext = React.createContext<StateContext>({
    state: initialState,
    currentPage: null,
    setCurrentPage: (page: CurrentPage) => {},
    updateSearch: () => {},
    updateFilters: () => {},
});

export default MyContext;
