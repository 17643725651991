import { createContext, useContext } from 'react';

interface iButtonContext {
    hasOrphanIcon: boolean;
    setHasOrphanIcon: (hasOrphanIcon: boolean) => void;
}

const useButton = () => {
    return useContext(ButtonContext);
};

const ButtonContext = createContext<iButtonContext>({
    hasOrphanIcon: false,
    setHasOrphanIcon: () => undefined,
});

export { ButtonContext, useButton };
