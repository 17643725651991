import styled, { css } from 'styled-components';

const NoPointerEvents = styled.div<{ disabled?: boolean }>`
    ${props =>
        props.disabled &&
        css`
            pointer-events: none;
        `};
`;

export { NoPointerEvents };
