import styled, { StyledProps } from 'styled-components';
import { TextProps } from 'components/text/index';

interface StyledTextProps extends Omit<TextProps, 'tag'> {}

type FontStyleTypes = {
    [key in NonNullable<TextProps['type']>]: {
        size?: string;
        height?: string;
        weight?: number;
        decoration?: string;
        color?: string;
    };
};

const FONTS_STYLES: FontStyleTypes = {
    display1: {
        size: 'xxxl',
        height: 'huge',
        weight: 600,
    },
    display2: {
        size: 'xxl',
        height: 'xxl',
        weight: 600,
    },
    display3: {
        size: 'xl',
        height: 'xxl',
        weight: 600,
    },
    bodyCopy: {
        size: 'md',
        height: 'xxl',
        weight: 400,
    },
    bodyLink: {
        size: 'md',
        height: 'xxl',
        weight: 600,
        decoration: 'underline',
    },
    label: {
        size: 'sm',
        height: 'md',
        weight: 600,
    },
    error: {
        size: 'xs',
        height: 'xm',
        weight: 500,
        color: 'error',
    },
    description: {
        size: 'xs',
        height: 'xm',
        weight: 500,
    },
    default: {
        size: 'md',
        height: 'xl',
        weight: 400,
    },
};

const getTextStyles = ({ theme, type, weight, size, fontHeight }: StyledProps<StyledTextProps>) => {
    const configs = FONTS_STYLES[type!] || FONTS_STYLES.default;
    return `
    font-size: ${theme.sizes.fonts[size! || configs.size]};
    line-height: ${theme.sizes.fonts[fontHeight! || configs.height]};
    font-weight: ${weight || configs.weight};
    color: ${configs.color ? theme.statuses[configs.color] : theme.colors.text.primary};
    text-decoration: ${configs.decoration || 'none'};
  `;
};

export const Text = styled.p<StyledTextProps>`
    display: inline-block;
    font-style: normal;
    margin: 0;
    padding: 0;
    ${getTextStyles};
`;
