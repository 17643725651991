import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import styled, { StyledProps, css } from 'styled-components';
import { CheckboxInputProps } from 'components/checkbox/Checkbox';
import { FocusVisible } from 'components/util';

const StyledCheckbox = styled(CheckboxPrimitive.Root)<StyledProps<CheckboxInputProps>>`
    all: unset;
    position: relative;
    cursor: pointer;
    background-color: white;
    width: 14px;
    height: 14px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1.5px solid ${props => props.theme.color.slate};

    &:focus-visible {
        ${FocusVisible};
    }

    &[data-state='checked'],
    &[data-state='indeterminate'] {
        background-color: ${props => props.theme.color.cobalt};
        border-color: ${props => props.theme.color.cobalt};
    }

    &[data-disabled=''] {
        background-color: ${props => props.theme.color.slate};
        border-color: ${props => props.theme.color.slate};
    }

    &:focus {
        border-color: ${props => props.theme.color.cobalt};
    }

    ${props =>
        props.error &&
        css`
            border-color: ${props => props.theme.color.error};
        `}

    ${props =>
        props.variant === 'dark' &&
        css`
            &[data-state='checked'] {
                background-color: ${props => props.theme.color.cobaltDark};
                border-color: ${props => props.theme.color.cobaltDark};
            }
        `}

    ${props =>
        props.size === 'lg' &&
        css`
            height: 16px;
            width: 16px;
        `}
`;

export const StyledCheckboxIndicator = styled(CheckboxPrimitive.CheckboxIndicator)`
    height: 100%;
    width: 100%;
    color: white;

    &[data-disabled=''] {
        color: ${props => props.theme.color.slateDark};
    }
`;

export { StyledCheckbox };
