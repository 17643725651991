import { css } from 'styled-components';

export const noSelect = css`
    user-select: none;
`;

export const ellipsis = css`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;
