import { LDProvider, useLDClient } from 'launchdarkly-react-client-sdk';
import React, { ReactNode, useEffect, useState } from 'react';
import { useCurrentAccount } from 'customHooks/useCurrentAccount';
import { useCurrentUser } from 'customHooks/useCurrentUser';

interface LaunchDarklyProviderProps {
    children?: ReactNode;
}

const LaunchDarklyProvider = ({ children }: LaunchDarklyProviderProps) => {
    const { ldHash, ...ldUser } = useLdUser();

    return (
        <LDProvider
            clientSideID={process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_KEY!}
            options={{
                hash: ldHash,
            }}
            user={ldUser}
            deferInitialization={true}
        >
            <LaunchDarklyInitManager>
                <LaunchDarklyWatcher>{children}</LaunchDarklyWatcher>
            </LaunchDarklyInitManager>
        </LDProvider>
    );
};

const LaunchDarklyInitManager = ({ children }: LaunchDarklyProviderProps) => {
    const ldClient = useLDClient();
    const [didLoad, setDidLoad] = useState(false);

    useEffect(() => {
        if (!didLoad) {
            ldClient?.waitForInitialization().finally(() => {
                setDidLoad(true);
            });
        }
    }, [didLoad, ldClient]);

    if (!didLoad) {
        return null;
    }

    return <>{children}</>;
};

const LaunchDarklyWatcher = ({ children }: LaunchDarklyProviderProps) => {
    const ldClient = useLDClient();
    const ldUser = useLdUser();

    useEffect(() => {
        if (
            ldClient &&
            ldClient.getUser()?.custom?.currentAccount !== ldUser.custom.currentAccount
        ) {
            const { ldHash, ...restLdUser } = ldUser;
            ldClient.identify(restLdUser, ldHash);
        }
    }, [ldUser, ldClient]);

    return <>{children}</>;
};

const useLdUser = () => {
    const { firstName, lastName, email, uid, ldHash } = useCurrentUser();
    const { accountId } = useCurrentAccount();

    return {
        key: uid,
        ldHash,
        firstName,
        lastName,
        email,
        custom: {
            currentAccount: accountId,
        },
    };
};

export { LaunchDarklyProvider };
