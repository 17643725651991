import React, { useEffect } from 'react';
import useDebounce from 'customHooks/useDebounce';
import Input from 'components/input';
import * as Styled from 'layouts/search-form/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';

interface SearchFormProps {
    name: string;
    search: string;
    sidebarCollapsed: boolean;
    onSubmit: () => void;
    onChange: (e: any) => void;
    disabled?: boolean;
}

const SearchForm = ({
    name,
    search,
    onSubmit,
    onChange,
    disabled = false,
    sidebarCollapsed,
}: SearchFormProps) => {
    const searchTerm = useDebounce(search, 500);

    useEffect(() => onSubmit(), [onSubmit, searchTerm]);

    return (
        <Styled.Form
            sidebarCollapsed={sidebarCollapsed}
            onSubmit={e => {
                e.preventDefault();
                onSubmit();
            }}
        >
            <FontAwesomeIcon size={'lg'} icon={faSearch} />
            <Input
                disabled={disabled}
                name={`search-${name}`}
                placeholder={`Search ${name}`}
                value={search}
                onChange={onChange}
            />
        </Styled.Form>
    );
};

export default SearchForm;
