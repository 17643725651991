const CONTACT_US_LINK = 'https://givebutter.com/contact';
const DOCS_LINK = 'https://docs.givebutter.com';
const HELP_CENTER_LINK = 'https://help.givebutter.com/en';
const FUNDRAISING_GUIDE_LINK = `https://givebutter.com/blog/category/fundraising-ideas`;
const TEXT_TO_DONATE_GUIDE_LINK = `${HELP_CENTER_LINK}/articles/4044807-how-to-configure-text-to-donate`;
const AUCTIONS_LEARN_MORE_LINK = 'https://givebutter.com/auctions';
const SIGNUP_LINK = 'https://givebutter.com/signup';
const CAMPAIGN_TYPE_LINK =
    'https://help.givebutter.com/en/articles/125627-how-to-choose-the-right-campaign-type-for-you';
const IMPORT_RECURRING_PLANS_LINK = `${HELP_CENTER_LINK}/articles/5489025-migrate-import-existing-recurring-plans-donations-to-givebutter#h_290f31164e`;
const STICKER_FORM_LINK =
    'https://docs.google.com/forms/d/e/1FAIpQLSf30XHxFXvup25ZDhhMuaA7IEIGODuERXjt9RiMQboQfuxX5A/viewform?usp=pp_url&entry.1648073931=';
const IOS_MOBILE_APP_LINK = 'https://givebutter.com/app/ios-download';
const ANDROID_MOBILE_APP_LINK = 'https://givebutter.com/app/android-download';
const THESHAREWAY_LINK =
    'https://www.theshareway.com/?utm_source=gb&utm_medium=web&utm_campaign=auction_tooltip';
const CHARIOT_HELP_LINK = `${HELP_CENTER_LINK}/articles/6764835-how-to-use-givebutter-s-daf-donation-method-via-chariot`;

const WIDGETS_LIB_LINK = process.env.REACT_APP_WIDGETS_LIB;
const WIDGETS_DOCS_LINK = `${DOCS_LINK}/docs/widgets`;
const WIDGETS_GUIDE_LINK = `${HELP_CENTER_LINK}/collections/3611528-website-embeds`;
const CAMPAIGN_TYPES_LINK = `${HELP_CENTER_LINK}/articles/125627-givebutter-s-products-explained`;
const CAMPAIGN_CUSTOM_RECEIPTS_LINK = `${HELP_CENTER_LINK}/articles/2219216-custom-receipts`;
const CAMPAIGN_FEE_TYPE_LINK = `${HELP_CENTER_LINK}/articles/4117457-tips-fees-and-our-commitment-to-100-transparency`;
const CAMPAIGN_EXPERTS_LINK = 'https://givebutter.com/experts';
const BETA_ACCESS_GUIDE_LINK = `${HELP_CENTER_LINK}/articles/8213753-early-access-beta`;
const CAMPAIGN_LIVE_DISPLAY_LINK = `${HELP_CENTER_LINK}/articles/6684564-how-to-launch-and-configure-your-live-display`;
const CAMPAIGN_SLUG_LINK = `${HELP_CENTER_LINK}/articles/2267413-custom-urls`;
const CAMPAIGN_SEO_LINK = `${HELP_CENTER_LINK}/articles/3574469-a-beginner-s-guide-to-search-engine-optimization-seo-for-fundraising-campaigns`;

export {
    BETA_ACCESS_GUIDE_LINK,
    CONTACT_US_LINK,
    DOCS_LINK,
    HELP_CENTER_LINK,
    FUNDRAISING_GUIDE_LINK,
    TEXT_TO_DONATE_GUIDE_LINK,
    AUCTIONS_LEARN_MORE_LINK,
    CAMPAIGN_TYPE_LINK,
    IMPORT_RECURRING_PLANS_LINK,
    STICKER_FORM_LINK,
    IOS_MOBILE_APP_LINK,
    ANDROID_MOBILE_APP_LINK,
    SIGNUP_LINK,
    THESHAREWAY_LINK,
    CHARIOT_HELP_LINK,
    WIDGETS_LIB_LINK,
    WIDGETS_DOCS_LINK,
    WIDGETS_GUIDE_LINK,
    CAMPAIGN_TYPES_LINK,
    CAMPAIGN_CUSTOM_RECEIPTS_LINK,
    CAMPAIGN_FEE_TYPE_LINK,
    CAMPAIGN_EXPERTS_LINK,
    CAMPAIGN_LIVE_DISPLAY_LINK,
    CAMPAIGN_SLUG_LINK,
    CAMPAIGN_SEO_LINK,
};
