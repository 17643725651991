import React, { ReactNode } from 'react';
import { Redirect } from 'react-router-dom';

interface RenderOrRedirectProps {
    cond: boolean;
    redirectTo: string;
    children?: ReactNode;
}

const RenderOrRedirect = (props: RenderOrRedirectProps) => {
    const { cond, children, redirectTo } = props;

    if (cond) {
        return <>{children}</>;
    }

    return <Redirect to={redirectTo} />;
};

export { RenderOrRedirect };
export type { RenderOrRedirectProps };
