import { createPipe, MaskedDateOptions } from 'imask';
import { DateTime } from 'luxon';
import { maskedTypes, unmaskedTypes } from 'masks/factory';

const DATE_MASK_FORMAT = 'MM/dd/yyyy';

const dateMaskOptions: MaskedDateOptions = {
    mask: Date,
    pattern: 'm{/}`d{/}`Y',
    lazy: true,
    eager: true,
    format: date => DateTime.fromJSDate(date!).toFormat(DATE_MASK_FORMAT),
    parse: str => DateTime.fromFormat(str, DATE_MASK_FORMAT).toJSDate(),
};

const unmaskDate = createPipe(dateMaskOptions as Omit<MaskedDateOptions, 'mask'>, ...maskedTypes);
const maskDate = createPipe(dateMaskOptions as Omit<MaskedDateOptions, 'mask'>, ...unmaskedTypes);

export { DATE_MASK_FORMAT, dateMaskOptions, maskDate, unmaskDate };
