import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Spin } from 'antd';
import React from 'react';

interface SpinnerProps {
    centered: boolean;
    fontSize: number;
}
class Spinner extends React.Component<SpinnerProps> {
    static defaultProps = {
        fontSize: 30,
        centered: false,
    };

    render() {
        const { centered, fontSize } = this.props;

        return (
            <div style={centered ? { display: 'flex', height: '100%' } : undefined}>
                <Spin
                    indicator={<FontAwesomeIcon icon={faSpinnerThird} style={{ fontSize }} spin />}
                    style={centered ? { margin: 'auto' } : undefined}
                />
            </div>
        );
    }
}

export default Spinner;
