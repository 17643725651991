import {
    ComponentPropsWithoutRef,
    createContext,
    MouseEvent,
    ReactNode,
    useContext,
    useEffect,
    useState,
} from 'react';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import {
    faInfoCircle,
    faCircleCheck,
    faExclamationCircle,
    faTimes,
} from '@fortawesome/pro-regular-svg-icons';

interface AlertNotificationProps {
    type: AlertType;
    className?: string;
    closable?: boolean;
    isClosed?: boolean;
    children?: ReactNode;
    onClose?: (e: MouseEvent) => void;
}

type AlertType = 'success' | 'error' | 'info';

interface IAlertNotificationContext {
    type: AlertType;
    close: (e: MouseEvent) => void;
}

const AlertNotificationContext = createContext<IAlertNotificationContext>({
    type: 'success',
    close: () => {},
});

const AlertNotification = ({
    type,
    children,
    onClose,
    isClosed: isClosedControlled,
    closable = false,
    className = '',
}: AlertNotificationProps) => {
    const [isClosed, setIsClosed] = useState(isClosedControlled || false);

    useEffect(() => {
        if (isClosedControlled) {
            setIsClosed(isClosedControlled);
        }
    }, [isClosedControlled]);

    const onCloseInternal = (e: MouseEvent) => {
        if (!isClosedControlled) {
            setIsClosed(true);
        }
        onClose && onClose(e);
    };

    if (isClosed) {
        return null;
    }

    const { border, background } = getAlertProps(type);

    return (
        <AlertNotificationContext.Provider value={{ type, close: onCloseInternal }}>
            <div
                className={`flex flex-row items-start gap-3 rounded-md border p-3 ${border} ${background} ${className}`}
            >
                {typeof children === 'string' ? (
                    <>
                        <AlertNotificationIcon />
                        <AlertNotificationContent>
                            <AlertNotificationTitle>{children}</AlertNotificationTitle>
                        </AlertNotificationContent>
                        {closable && <AlertNotificationClose />}
                    </>
                ) : (
                    children
                )}
            </div>
        </AlertNotificationContext.Provider>
    );
};

interface AlertNotificationContentProps extends ComponentPropsWithoutRef<'div'> {}

const AlertNotificationContent = (props: AlertNotificationContentProps) => {
    const { className = '', ...rest } = props;

    return <div className={`flex flex-grow flex-col gap-0.5 ${className}`} {...rest} />;
};

interface AlertNotificationTitleProps extends ComponentPropsWithoutRef<'p'> {}

const AlertNotificationTitle = (props: AlertNotificationTitleProps) => {
    const { className = '', ...rest } = props;
    const { type } = useContext(AlertNotificationContext);
    const { text } = getAlertProps(type);

    return <p className={`text-sm font-medium leading-none ${text} ${className}`} {...rest} />;
};

interface AlertNotificationDescriptionProps extends ComponentPropsWithoutRef<'p'> {}

const AlertNotificationDescription = (props: AlertNotificationDescriptionProps) => {
    const { className = '', ...rest } = props;
    const { type } = useContext(AlertNotificationContext);
    const { text } = getAlertProps(type);

    return <p className={`text-sm font-normal ${text} ${className}`} {...rest} />;
};

interface AlertNotificationIconProps extends Omit<FontAwesomeIconProps, 'icon'> {}

const AlertNotificationIcon = (props: AlertNotificationIconProps) => {
    const { className = '' } = props;
    const { type } = useContext(AlertNotificationContext);
    const { iconColor, icon } = getAlertProps(type);

    return <FontAwesomeIcon className={`${iconColor} ${className}`} icon={icon} {...props} />;
};

interface AlertNotificationCloseProps extends ComponentPropsWithoutRef<'button'> {}

const AlertNotificationClose = (props: AlertNotificationCloseProps) => {
    const { className = '', ...rest } = props;
    const { type, close } = useContext(AlertNotificationContext);
    const { iconColor } = getAlertProps(type);

    return (
        <button onClick={close} className={`${iconColor} ${className}`} {...rest}>
            <FontAwesomeIcon icon={faTimes} />
        </button>
    );
};

const getAlertProps = (alertType: AlertType) => {
    const props = {
        text: 'text-green-700',
        border: 'border-green-300',
        background: 'bg-green-25',
        iconColor: 'text-green-500',
        icon: faCircleCheck,
    };

    switch (alertType) {
        case 'error':
            props.text = 'text-red-700';
            props.border = 'text-red-300';
            props.background = 'bg-red-25';
            props.iconColor = 'text-red-500';
            props.icon = faExclamationCircle;
            break;
        case 'info':
            props.text = 'text-gray-700';
            props.border = 'text-gray-300';
            props.background = 'bg-white';
            props.iconColor = 'text-gray-500';
            props.icon = faInfoCircle;
            break;
        case 'success':
        default:
    }

    return props;
};

const Root = AlertNotification;
const Content = AlertNotificationContent;
const Title = AlertNotificationTitle;
const Description = AlertNotificationDescription;
const Icon = AlertNotificationIcon;
const Close = AlertNotificationClose;

export {
    AlertNotification,
    AlertNotificationContent,
    AlertNotificationTitle,
    AlertNotificationDescription,
    AlertNotificationIcon,
    AlertNotificationClose,
    //
    Root,
    Content,
    Title,
    Description,
    Icon,
    Close,
};

export type {
    AlertType,
    AlertNotificationProps,
    AlertNotificationContentProps,
    AlertNotificationTitleProps,
    AlertNotificationDescriptionProps,
    AlertNotificationIconProps,
    AlertNotificationCloseProps,
    IAlertNotificationContext,
};
