const Settings = {
    MetaTitle: {
        Code: 100,
        Name: 'meta_title',
    },

    MetaDescription: {
        Code: 101,
        Name: 'meta_description',
    },

    FacebookPixel: {
        Code: 108,
        Name: 'facebook_pixel',
    },

    ThemeColor: {
        Code: 109,
        Name: 'theme_color',
    },

    EnableContacts: {
        Code: 112,
        Name: 'enable_contacts',
    },

    CustomSubscription: {
        Code: 113,
        Name: 'custom_subscription',
    },

    GoogleAnalytics: {
        Code: 114,
        Name: 'google_analytics',
    },

    EnabledIntegrations: {
        Code: 202,
        Name: 'integrations',
    },

    HideMoneyRaised: {
        Code: 203,
        Name: 'hide_money_raised',
    },

    CampaignSpecificThemeColor: {
        Code: 205,
        Name: 'theme_color',
    },

    MaintainAspectRatio: {
        Code: 208,
        Name: 'use_actual_cover_size',
    },

    DonationButtonText: {
        Code: 210,
        Name: 'custom_donate_text',
    },

    DisablePayments: {
        Code: 300,
        Name: 'disable_donations',
    },

    EnableRecurringPayments: {
        Code: 301,
        Name: 'enable_recurring',
    },

    MinimumDonationRequired: {
        Code: 301,
        Name: 'minimum_donation',
    },

    RequirePhoneNumbers: {
        Code: 302,
        Name: 'require_donor_phone',
    },

    ExpressCheckout: {
        Code: 303,
        Name: 'express_checkout',
    },

    ThanksMessage: {
        Code: 304,
        Name: 'thank_you_message',
    },

    ForceEndDate: {
        Code: 304,
        Name: 'enforce_end_at',
    },

    EnableDedications: {
        Code: 305,
        Name: 'dedications',
    },

    DisableRecurringPayments: {
        Code: 305,
        Name: 'disable_recurring',
    },

    CampaignSpecificThanksMessage: {
        Code: 306,
        Name: 'thank_you_message',
    },

    MinimumPayoutPercentage: {
        Code: 306,
        Name: 'minimum_payout_tip',
    },

    EnableCustomBranding: {
        Code: 307,
        Name: 'custom_branding',
    },

    DefaultFund: {
        Code: 307,
        Name: 'default_fund',
    },

    HideFund: {
        Code: 308,
        Name: 'hide_fund',
    },

    RequireBillingAddress: {
        Code: 309,
        Name: 'require_address',
    },

    DefaultPaymentFrequency: {
        Code: 311,
        Name: 'default_frequency',
    },

    EnabledPaymentFrequencies: {
        Code: 312,
        Name: 'display_frequencies',
    },

    CheckPaymentInstructions: {
        Code: 313,
        Name: 'public_check_payments',
    },

    HideFees: {
        Code: 350,
        Name: 'hide_fee_collection',
    },

    RequireFees: {
        Code: 351,
        Name: 'force_fee_collection',
    },

    CustomDonations: {
        Code: 358,
        Name: 'custom_donation_amounts',
    },

    DisableDedications: {
        Code: 367,
        Name: 'disable_dedications',
    },

    HideOtherAmountField: {
        Code: 369,
        Name: 'hide_other_option',
    },

    DisableTips: {
        Code: 371,
        Name: 'remove_tips_increase_fee',
    },

    CustomFeeText: {
        Code: 372,
        Name: 'custom_fee_text',
    },

    EngageLogoHyperlink: {
        Code: 400,
        Name: 'engage_logo_hyperlink',
    },

    DisableTeamFundraising: {
        Code: 500,
        Name: 'disable_team_fundraising',
    },

    DisableFundraiseButton: {
        Code: 502,
        Name: 'disable_public_join_button',
    },

    FundraiseButtonText: {
        Code: 505,
        Name: 'custom_join_text',
    },

    DisableCreatingNewTeams: {
        Code: 506,
        Name: 'disallow_new_teams',
    },

    EnableMultiTeam: {
        Code: 514,
        Name: 'multi_team_fundraising',
    },

    MultiTeamLimit: {
        Code: 515,
        Name: 'multi_team_fundraising_limit',
    },

    EnableEventReminders: {
        Code: 602,
        Name: 'enable_event_reminders',
    },

    PayPalBeta: {
        Code: 701,
        Name: 'beta_paypal',
    },

    EngageBeta: {
        Code: 702,
        Name: 'engage_beta',
    },

    EngageWaitList: {
        Code: 703,
        Name: 'engage_waitlist',
    },
};

const AddressType = {
    Home: 'home',
    Work: 'work',
};

const PhoneType = {
    Home: 'home',
    Work: 'work',
    Cell: 'cell',
};

const EmailType = {
    Personal: 'personal',
    Work: 'work',
};

const ContactActivityType = {
    TicketIssued: 'ticket.issued',
    TransactionSucceeded: 'transaction.succeeded',
    RecurringPlanCreated: 'recurring_plan.created',
};

export { Settings, PhoneType, EmailType, AddressType, ContactActivityType };
