import styled, { css } from 'styled-components';
import { Campaign } from '../../types/domain';

export const SidebarContainer = styled.aside<{ collapsed: boolean; notification: any }>`
    position: fixed;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 999;
    width: 330px;
    overflow-y: scroll;
    box-sizing: content-box;
    background-color: #222;
    border-right: 1px solid ${p => p.theme.color.slateLight};
    flex: 0 0 330px;
    min-width: 330px;
    transition: ${p => p.theme.utility.transition};
    color: white;
    padding-bottom: 2rem;

    @media (min-width: ${p => p.theme.breakpoint.mobile}) {
        ${p =>
            p.collapsed &&
            css`
                flex: 0 0 80px;
                min-width: 80px;
                width: 80px;
                overflow: visible;
            `}
    }

    @media (max-width: ${p => p.theme.breakpoint.mobile}) {
        position: absolute;
        width: 100%;
        right: 0;
        border: none;
        font-size: 16px;
        overflow-y: scroll;
        box-shadow: 0 0 50px rgba(0, 0, 0, 0.7);

        ${p =>
            p.collapsed &&
            css`
                flex: 0 0 0;
                min-width: 0;
                width: 0;
                overflow: hidden;
            `}
    }

    ${p =>
        p.notification &&
        css`
            margin-top: 2.2rem;
        `}
`;

export const SidebarItemsContainer = styled.div<{ sidebarCollapsed: boolean }>`
    margin: 1.5rem 1.5rem 4rem 1.5rem;
    box-sizing: border-box;
    position: relative;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (min-width: ${p => p.theme.breakpoint.mobile}) {
        ${p =>
            p.sidebarCollapsed &&
            css`
                margin: 1rem;
                height: 80%;
            `}
    }
`;

export const SidebarItem = styled.div<{
    active: boolean;
    collapsed: boolean;
}>`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1rem;
    transition: ${p => p.theme.utility.transition};
    border-radius: ${p => p.theme.radius.default};
    box-sizing: border-box;
    color: white;

    span svg {
        margin-right: 1rem;
        opacity: 0.6;
    }

    @media (min-width: ${p => p.theme.breakpoint.mobile}) {
        ${p =>
            p.collapsed &&
            css`
                justify-content: center;
                margin-bottom: 1rem;

                span svg {
                    margin-right: 0;
                }
            `}
    }

    @media (max-width: ${p => p.theme.breakpoint.mobile}) {
        margin-bottom: 1rem;
    }

    ${p =>
        !p.active &&
        css`
            &:hover {
                svg {
                    opacity: 1;
                    transition: ${p => p.theme.utility.transition};
                    color: #fff;
                }
            }
        `}

    ${p =>
        p.active &&
        css`
            background-color: rgba(255, 255, 255, 0.07);

            span svg {
                opacity: 1;
            }
        `}
`;

export const SidebarText = styled.span<{ sidebarCollapsed: boolean }>`
    font-weight: 700;
    display: flex;
    align-items: center;

    @media (min-width: ${p => p.theme.breakpoint.mobile}) {
        ${p =>
            p.sidebarCollapsed &&
            css`
                span {
                    display: none;
                }
            `}
    }

    @media (max-width: ${p => p.theme.breakpoint.mobile}) {
        width: 100%;
    }
`;

export const SidebarCampaigns = styled.div<{ collapsed: boolean; sidebarCollapsed: boolean }>`
    margin-left: 3.6rem;
    box-sizing: border-box;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
    color: #fff;
    max-height: 1000px;
    overflow: hidden;
    transition: ${p => p.theme.utility.transition};

    @media (min-width: ${p => p.theme.breakpoint.mobile}) {
        ${p =>
            p.sidebarCollapsed &&
            css`
                position: absolute;
                left: 54px;
                top: 4rem;
                background: ${p => p.theme.colors.onyx};
                border-radius: ${p => p.theme.radius.default};
                width: 300px;
                z-index: 1000;
                margin: 0;
                border: 1px solid ${p => p.theme.color.slate};
                padding: 1rem;
            `}
    }

    @media (max-width: ${p => p.theme.breakpoint.mobile}) {
        margin-top: -1rem;
        margin-bottom: 1rem;
    }

    ${p =>
        p.collapsed &&
        css`
            max-height: 0;
            margin-bottom: 0;
            margin-top: 0;
            display: none;
        `}
`;

export const SidebarCampaign = styled.span<{
    active: boolean;
    selected: boolean;
    type?: Campaign['type'];
}>`
    display: inline-block;
    font-size: ${p => p.theme.sizes.fonts.sm};
    font-weight: 400;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 210px;
    box-sizing: border-box;
    border-radius: ${props => props.theme.radius.default};
    padding: 0.25rem;
    margin-bottom: -10px;
    color: #fff;

    &::before {
        content: '';
        height: 0.35rem;
        width: 0.35rem;
        display: inline-block;
        margin-right: 0.5rem;
        border-radius: 50%;
        margin-bottom: 1px;
        margin-left: 3px;
    }

    @media (max-width: ${p => p.theme.breakpoint.mobile}) {
        width: 100%;
    }

    ${props =>
        props.active &&
        css`
            &::before {
                background-color: ${props => props.theme.statuses.succeeded};
            }
        `}

    ${props =>
        !props.active &&
        css`
            &::before {
                background-color: ${props => props.theme.statuses.error};
            }
        `}

  ${props =>
        props.active &&
        props.type === 'general' &&
        css`
            &::before {
                background-color: ${props => props.theme.color.cobalt};
            }
        `}

  ${props =>
        props.selected &&
        css`
            background-color: rgba(0, 0, 0, 0.2);
            color: white;
        `}
`;

export const AccountName = styled.span<{ sidebarCollapsed: boolean }>`
    display: flex;
    justify-content: space-between;
    width: 100%;

    @media (min-width: ${p => p.theme.breakpoint.mobile}) {
        ${p =>
            p.sidebarCollapsed &&
            css`
                display: none;
            `}
    }
`;

export const AccountLogo = styled.div<{ sidebarCollapsed: boolean }>`
    border-radius: ${props => props.theme.radius.default};
    overflow: hidden;
    height: 2rem;
    width: 2rem;
    background-color: white;
    margin-right: 0.75rem;
    flex-shrink: 0;

    img {
        width: auto;
        height: 100%;
        display: block;
        margin: auto;
        object-fit: contain;
    }

    @media (min-width: ${p => p.theme.breakpoint.mobile}) {
        ${p =>
            p.sidebarCollapsed &&
            css`
                margin: 0 auto;
            `}
    }
`;

export const NewCampaignButton = styled.button`
    font-size: ${p => p.theme.sizes.fonts.xs};
    padding: 0.25rem 1rem;
    font-weight: 600;
    color: white;
    cursor: pointer;
    transition: ${p => p.theme.utility.transition};
    border-radius: ${p => p.theme.radius.default};
    box-sizing: border-box;
    border: 1px solid rgba(255, 255, 255, 0.2);
    background-color: transparent;
    margin-top: 0.75rem;
    margin-bottom: 0.5rem;

    &:hover {
        border-color: rgba(255, 255, 255, 0.3);
    }
`;

export const TopContainer = styled.div<{ collapsed: boolean }>`
    padding: 0 ${({ collapsed }) => (collapsed ? 0 : 2)}rem;
`;

export const LogoContainer = styled.div<{ collapsed: boolean }>`
    display: flex;
    justify-content: ${({ collapsed }) => (collapsed ? 'center' : 'space-between')};
    align-items: center;
    width: 100%;
    height: 70px;
`;

export const CollapsedContainer = styled.div<{ collapsed: boolean }>`
    display: ${({ collapsed }) => (collapsed ? 'flex' : 'none')};
    z-index: 1000;
    justify-content: center;
    align-items: center;
    width: 70px;
    height: 70px;

    & svg {
        color: black;
    }
`;

export const CollapseButton = styled.button`
    background: transparent;
    border: none;
    padding: 0.25rem;
    margin: 0;
    cursor: pointer;
    color: white;
    height: 2rem;
    width: 2rem;
    border-radius: ${props => props.theme.radius.default};
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &:hover {
        background-color: rgba(255, 255, 255, 0.05);
    }
`;

export const SidebarHeader = styled.div<{ sidebarCollapsed: boolean }>`
    display: flex;
    align-items: center;
    padding: 0.75rem 1rem;
    background: rgba(0, 0, 0, 0.07);
    border-radius: ${p => p.theme.radius.default};
    margin: 0 1.5rem 1.5rem 1.5rem;
    cursor: pointer;
    border: 1px solid
        ${({ sidebarCollapsed }) => (sidebarCollapsed ? 'transparent' : 'rgba(255, 255, 255, 0.2)')};

    @media (min-width: ${p => p.theme.breakpoint.mobile}) {
        ${p =>
            p.sidebarCollapsed &&
            css`
                background: transparent;
                margin: 0 0 2rem 0;

                img {
                    margin: 0;
                }
            `}
    }
`;

export const SidebarCollapseButton = styled.button`
    background: transparent;
    border: none;
    cursor: pointer;
    width: 100%;
    padding: 0;
    margin: 0;
`;

export const Label = styled.label`
    display: block;
    font-weight: 700;
    letter-spacing: 1px;
    color: rgba(255, 255, 255, 0.7);
    font-size: 10px;
    text-transform: uppercase;
    margin-bottom: 0.25rem;
    margin-top: 0.75rem;
`;

export const SidebarSubItems = styled.div<{ collapsed: boolean; sidebarCollapsed: boolean }>`
    max-height: 400px;
    transition: ${p => p.theme.utility.transition};
    position: relative;
    overflow: hidden;
    font-size: ${p => p.theme.typography.body.size};
    margin: 0.5rem 0 1rem 3.6rem;

    ${p =>
        p.collapsed &&
        css`
            max-height: 0;
            transition: ${p => p.theme.utility.transition};
            display: none;
        `};

    @media (min-width: ${p => p.theme.breakpoint.mobile}) {
        ${p =>
            p.sidebarCollapsed &&
            css`
                position: absolute;
                left: 54px;
                top: 7.5rem;
                background: ${p => p.theme.colors.onyx};
                border-radius: ${p => p.theme.radius.default};
                width: 300px;
                z-index: 1000;
                margin: 0;
                border: 1px solid ${p => p.theme.color.slate};
                padding: 1rem;
            `}
    }

    @media (max-width: ${p => p.theme.breakpoint.mobile}) {
        font-size: 16px;
        margin-top: -1rem;
        margin-bottom: 1rem;
    }
`;

export const SidebarSubItem = styled.span<{ active: boolean; last?: boolean }>`
    font-size: ${p => p.theme.typography.body.size};
    color: rgba(255, 255, 255, 0.7);
    transition: ${p => p.theme.utility.transition};
    display: block;
    font-weight: 400;
    margin-bottom: 0.3rem;

    &:hover {
        color: white;
    }

    ${p =>
        p.active &&
        css`
            color: white;
        `}

    ${p =>
        p.last &&
        css`
            margin-bottom: 0;
        `}
`;
