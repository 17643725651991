enum IntercomProductTourTargets {
    UserMenu = 'user-menu',
    RaiseLink = 'raise-link',
    TrackLink = 'track-link',
    EngageLink = 'engage-link',
    PayoutsLink = 'payouts-link',
    AccountSettingsLink = 'account-settings-link',
    CampaignsLink = 'campaigns-link',
    CampaignAuctionsTab = 'campaign-auctions-tab',
    AuctionGettingStartedButton = 'auction-getting-started-button',
    AuctionSettingsSubnav = 'auction-settings-subnav',
    AuctionSettingsTitle = 'auction-settings-title',
    AuctionSettingsBiddingMethod = 'auction-settings-bidding-method',
    AuctionNotificationsSubnav = 'auction-notifications-subnav',
    AuctionNotificationsTitle = 'auction-notifications-title',
    AuctionItemsSubnav = 'auction-items-subnav',
    AuctionItemsTitle = 'auction-items-title',
    AuctionNewItemButton = 'auction-new-item-button',
    AuctionBiddersSubnav = 'auction-bidders-subnav',
    AuctionBiddersTitle = 'auction-bidders-title',
    AuctionWinningBidsSubnav = 'auction-winningbids-subnav',
    AuctionWinningBidsTitle = 'auction-winningbids-title',
    AuctionFulfillmentSubnav = 'auction-fulfillment-subnav',
    AuctionFulfillmentTitle = 'auction-fulfillment-title',
}

export { IntercomProductTourTargets };
