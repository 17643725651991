import { CheckboxProps } from '@radix-ui/react-checkbox';
import React, { ForwardedRef, forwardRef } from 'react';
import { StyledCheckbox, StyledCheckboxIndicator } from 'components/checkbox/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faCheck } from '@fortawesome/pro-solid-svg-icons';

interface CheckboxInputProps extends CheckboxProps {
    variant?: 'primary' | 'dark';
    size?: 'md' | 'lg';
    error?: boolean;
}

const Checkbox = forwardRef((props: CheckboxInputProps, ref: ForwardedRef<HTMLButtonElement>) => {
    const { variant = 'primary', size = 'md', checked, ...restCheckboxProps } = props;

    return (
        <Root ref={ref} checked={checked} variant={variant} size={size} {...restCheckboxProps}>
            <Indicator className={'flex flex-col items-center justify-center'}>
                {checked === 'indeterminate' && <FontAwesomeIcon icon={faMinus} />}
                {checked === true && <FontAwesomeIcon icon={faCheck} />}
            </Indicator>
        </Root>
    );
});

const CheckboxRoot = StyledCheckbox;
const CheckboxIndicator = StyledCheckboxIndicator;

const Root = CheckboxRoot;
const Indicator = CheckboxIndicator;

export {
    Checkbox,
    CheckboxRoot,
    CheckboxIndicator,
    //
    Root,
    Indicator,
};
export type { CheckboxInputProps };
