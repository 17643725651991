import { StyledProps, css } from 'styled-components';
import { Sizeable } from './types';

// @TODO: use theme vs hardcoded
const SizeableStyles = css<StyledProps<Sizeable>>`
    ${({ inputSize = 'md' }) => {
        switch (inputSize) {
            case 'sm':
                return css`
                    font-size: 14px;
                    height: 32px;
                `;
            case 'md':
                return css`
                    font-size: 15px;
                    height: 40px;
                `;
            case 'lg':
                return css`
                    font-size: 18px;
                    height: 48px;
                `;
        }
    }}
`;

const SizeableSquare = css<StyledProps<Sizeable>>`
    ${SizeableStyles};
    ${({ inputSize = 'md' }) => {
        switch (inputSize) {
            case 'sm':
                return css`
                    width: 32px;
                `;
            case 'md':
                return css`
                    width: 40px;
                `;
            case 'lg':
                return css`
                    font-size: 18px;
                    width: 48px;
                `;
        }
    }}
`;

const ExpandableSize = css<StyledProps<Sizeable>>`
    ${({ inputSize = 'md' }) => {
        switch (inputSize) {
            case 'sm':
                return css`
                    font-size: 14px;
                    min-height: 32px;
                `;
            case 'md':
                return css`
                    font-size: 15px;
                    min-height: 40px;
                `;
            case 'lg':
                return css`
                    font-size: 18px;
                    min-height: 48px;
                `;
        }
    }}
`;

export { SizeableStyles, SizeableSquare, ExpandableSize };
