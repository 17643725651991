/* eslint-disable jsx-a11y/anchor-is-valid */
import { Dropdown, Layout, Menu } from 'antd';
import React, { useContext } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { IntercomProductTourTargets } from 'constants/intercom';
import StateContext, { StateContext as IStateContext } from 'customHooks/stateContext/context';
import SearchForm from 'layouts/search-form';
import 'assets/css/Header.css';
import Context from 'assets/js/Context';
import NotificationHeader from 'assets/js/components/NotificationHeader';
import Sidebar from 'components/sidebar/Sidebar';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle, faComment, faGift } from '@fortawesome/pro-regular-svg-icons';

const { Header: MainHeader } = Layout;

const nameMapping = {
    transactions: 'Transactions',
    contacts: 'Contacts',
    tickets: 'Tickets',
    chapters: 'Chapters',
    plans: 'Recurring Plans',
    campaigns: 'Campaigns',
};

const pagesWithSearch = ['transactions', 'contacts', 'tickets', 'plans', 'chapters', 'campaigns'];

interface HeaderProps extends RouteComponentProps {
    currentPage: keyof IStateContext['state'] | null;
    setCurrentPage: Function;
    updateSearch: Function;
    onboarding: boolean;
    search?: string;
}

interface HeaderState {
    search: string;
}

class Header extends React.Component<HeaderProps, HeaderState> {
    constructor(props: HeaderProps) {
        super(props);
        this.state = {
            search: props.search || '',
        };
    }

    componentDidMount() {
        this.setCurrentPage(this.props.location.pathname);
        window.Canny('initChangelog', {
            align: 'right',
            position: 'bottom',
            appID: '5d138ebf5574b67029ffab6c',
        });
    }

    onClickPreviousAccount() {
        const { previousAccount, previousLocation, setCurrentAccount, setPreviousAccount } =
            this.context;

        const { history } = this.props;

        setPreviousAccount(null);
        setCurrentAccount(previousAccount);

        history.push(previousLocation);
    }

    onClickLogoutButton() {
        this.context.logout();
    }

    renderGiftBox() {
        return <FontAwesomeIcon icon={faGift} />;
    }

    renderAccountMenu() {
        return (
            <Menu>
                <Menu.Item className="profile-item">
                    <a
                        href={`${this.context.apiUrl}/profile`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        My Profile
                    </a>
                </Menu.Item>

                <Menu.Item className="profile-item">
                    <a onClick={this.onClickLogoutButton.bind(this)}>Logout</a>
                </Menu.Item>
            </Menu>
        );
    }

    renderSupportMenu() {
        return (
            <Menu className="profile-menu">
                <Menu.Item className="profile-item">
                    <a
                        href="https://givebutter.com/support"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Contact Us
                    </a>
                </Menu.Item>

                <Menu.Item className="profile-item">
                    <a href="https://help.givebutter.com" target="_blank" rel="noopener noreferrer">
                        Help Center
                    </a>
                </Menu.Item>

                <Menu.Item className="profile-item">
                    <a
                        href="https://community.givebutter.com"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Request a Feature
                    </a>
                </Menu.Item>

                <Menu.Item className="profile-item">
                    <a href="https://givebutter.com/blog" target="_blank" rel="noopener noreferrer">
                        Butter Blog
                    </a>
                </Menu.Item>
            </Menu>
        );
    }

    setCurrentPage = (path: string) => {
        const currentPage = path.split('/').pop();
        this.props.setCurrentPage(
            currentPage && pagesWithSearch.includes(currentPage) ? currentPage : ''
        );
    };

    componentDidUpdate(prevProps: HeaderProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.setCurrentPage(this.props.location.pathname);
        }
        if (this.props.search && this.props.search !== prevProps.search) {
            this.setState({ search: this.props.search });
        }
    }

    handleSearchSubmit = () => this.props.updateSearch(this.state.search);

    render() {
        const { currentUser, currentAccount, previousAccount } = this.context;
        const { currentPage } = this.props;
        let className = 'header-navigation';

        if (previousAccount || (!this.props.onboarding && !currentAccount.can_accept_payments)) {
            className = `${className} notification-visible`;
        }

        return (
            <React.Fragment>
                <Sidebar onboarding={this.props.onboarding} />
                <MainHeader className={className}>
                    <ActionLinksContainer style={{ float: 'right' }}>
                        <Dropdown
                            placement="bottomRight"
                            trigger={['click']}
                            overlay={this.renderAccountMenu()}
                        >
                            <div
                                data-intercom-target={IntercomProductTourTargets.UserMenu}
                                className="logo"
                                style={{ float: 'right' }}
                            >
                                <img alt={currentUser.first_name} src={currentUser.picture} />
                            </div>
                        </Dropdown>
                        <Dropdown
                            placement="bottomRight"
                            trigger={['click']}
                            overlay={this.renderSupportMenu()}
                        >
                            <div className="support-link">
                                <FontAwesomeIcon icon={faQuestionCircle} />
                            </div>
                        </Dropdown>
                        <div className="support-link gift-link" data-canny-changelog>
                            {this.renderGiftBox()}
                        </div>
                        <ChatButton
                            className="intercom-chat-btn"
                            onClick={() => window.services.chat()}
                        >
                            <FontAwesomeIcon icon={faComment} />
                            Chat
                        </ChatButton>
                    </ActionLinksContainer>
                    {currentPage && (
                        <SearchForm
                            sidebarCollapsed={this.context.sidebarCollapsed}
                            name={nameMapping[currentPage]}
                            onSubmit={this.handleSearchSubmit}
                            onChange={e => this.setState({ search: e.target.value })}
                            search={this.state.search}
                        />
                    )}
                </MainHeader>
                {!this.props.onboarding &&
                    !currentAccount.can_accept_payments &&
                    currentAccount.is_activated && (
                        <NotificationHeader backgroundColor={'#f4434c'}>
                            Please visit the{' '}
                            <Link
                                to={`/accounts/${currentAccount.id}/payouts`}
                                style={{ color: 'white', textDecoration: 'underline' }}
                            >
                                payouts tab
                            </Link>{' '}
                            to setup your withdrawal method and begin accepting payments.
                        </NotificationHeader>
                    )}
                {!this.props.onboarding &&
                    !currentAccount.can_accept_payments &&
                    !currentAccount.is_activated && (
                        <NotificationHeader backgroundColor={'#f4434c'}>
                            Please{' '}
                            <a
                                style={{ color: 'white', textDecoration: 'underline' }}
                                onClick={() => window.services.chat()}
                            >
                                contact us
                            </a>{' '}
                            to activate your account and begin accepting payments.
                        </NotificationHeader>
                    )}
                {previousAccount && (
                    <NotificationHeader>
                        You are currently viewing {this.context.currentAccount.title} account.{' '}
                        <a className="link" onClick={this.onClickPreviousAccount.bind(this)}>
                            Back to my account
                        </a>
                        .
                    </NotificationHeader>
                )}
            </React.Fragment>
        );
    }
}

Header.contextType = Context;

const HeaderWrapper = ({ ...props }) => {
    const { state, currentPage, setCurrentPage, updateSearch } = useContext(StateContext);
    const { onboarding } = useFlags();

    return (
        <Header
            {...(props as HeaderProps)}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            search={currentPage ? state[currentPage]?.search : undefined}
            updateSearch={(value: string) => updateSearch(currentPage, value)}
            onboarding={onboarding}
        />
    );
};

export default withRouter(HeaderWrapper);

const ActionLinksContainer = styled.div`
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    height: 100%;
`;

const ChatButton = styled.button`
    display: flex;
    height: 2rem;
    padding: 0 0.75rem;
    align-items: center;
    cursor: pointer;
    border-radius: ${p => p.theme.radius.default};
    font-weight: 600;
    border: 1px solid ${p => p.theme.color.slate};
    transition: ${p => p.theme.utility.transition};
    color: ${p => p.theme.color.onyx};
    background: transparent;

    &:hover {
        border-color: ${p => p.theme.color.onyx};
    }

    svg {
        margin-right: 0.5rem;
    }
`;
