import React from 'react';
import { Button } from 'components/button2';
import { useGlobalState } from 'customHooks/useGlobalState';

interface ErrorSplashProps {
    error: Error;
}

const ErrorSplash = (props: ErrorSplashProps) => {
    const { apiUrl } = useGlobalState();
    const { error } = props;

    return (
        <div className={'flex h-screen w-screen flex-col items-center justify-center space-y-3.5'}>
            <img
                alt={'Givebutter text logo'}
                className={'w-[268px]'}
                src={`${process.env.PUBLIC_URL}/images/logo-orange.png`}
            />
            {error.name !== 'ChunkLoadError' && (
                <>
                    <p className={'text-xl font-semibold'}>Sorry, an unexpected error occurred.</p>
                    <p className={'text-xl font-semibold'}>
                        Please refresh the page.{' '}
                        <a
                            target="_blank"
                            className="text-blue-500"
                            rel="noreferrer noopener"
                            href={`${apiUrl}/support`}
                        >
                            Contact us
                        </a>{' '}
                        if this error persists.
                    </p>
                </>
            )}
            <Button
                onClick={() => {
                    window.location.href = `${
                        window.location.href.replace(/#.*$/, '').split('?')[0]
                    }?refresh=${Date.now()}`;
                }}
            >
                Retry
            </Button>
        </div>
    );
};

export { ErrorSplash };
