import React, { ComponentPropsWithoutRef, ReactNode } from 'react';
import * as Styled from 'components/input/styled';
import Text from 'components/text';

export interface InputProps extends ComponentPropsWithoutRef<'input'> {
    description?: string;
    label?: ReactNode;
    inline?: boolean;
    error?: string | ReactNode;
    tag?: string;
}

const Input = React.forwardRef(
    (
        {
            name,
            placeholder,
            onChange,
            defaultValue,
            value,
            description = '',
            label = '',
            inline = false,
            disabled = false,
            autoFocus = false,
            error = null,
            className = '',
            type,
            tag = 'input', // deprecate,
            // multiline,
            ...rest
        }: InputProps,
        ref: React.Ref<HTMLInputElement>
    ) => {
        return (
            <Styled.Label inline={inline} className={className}>
                {label && <Styled.LabelText type="label">{label}</Styled.LabelText>}
                <Styled.InputWrapper>
                    {description && <Text type="description">{description}</Text>}
                    <Styled.Input
                        ref={ref}
                        as={tag === 'input' ? 'input' : 'textarea'}
                        inline={inline}
                        error={!!error}
                        name={name}
                        placeholder={placeholder}
                        onChange={onChange}
                        value={value}
                        disabled={disabled}
                        defaultValue={defaultValue}
                        autoFocus={autoFocus}
                        type={type}
                        {...rest}
                    />
                    {error && <Text type="error">{error}</Text>}
                </Styled.InputWrapper>
            </Styled.Label>
        );
    }
);

export default Input;
