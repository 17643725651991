import React, { ReactNode } from 'react';
import { ThemeProvider as ThemeProviderPrimitive } from 'styled-components';
import { useTheme } from 'helpers/themes';

interface ThemeProviderProps {
    children?: ReactNode;
}

const ThemeProvider = ({ children = null }: ThemeProviderProps) => {
    const { theme, changeTheme } = useTheme();
    return (
        <ThemeProviderPrimitive
            theme={{
                ...theme,
                // @ts-ignore
                changeTheme,
            }}
        >
            {children}
        </ThemeProviderPrimitive>
    );
};

export { ThemeProvider };
