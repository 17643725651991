import * as LabelPrimitive from '@radix-ui/react-label';
import { PrimitiveLabelProps } from '@radix-ui/react-label';
import { twMerge } from 'tailwind-merge';

interface LabelProps extends PrimitiveLabelProps {}

const Label = (props: LabelProps) => {
    const { className = '', ...rest } = props;
    return <LabelPrimitive.Root className={twMerge('text-sm font-medium', className)} {...rest} />;
};

const LabelAsterisk = () => <span className={'font-medium text-red-500'}>*</span>;

const Root = Label;
const Asterisk = LabelAsterisk;

export {
    Label,
    LabelAsterisk,
    //
    Root,
    Asterisk,
};
