import { message } from 'antd';
import { get } from 'lodash';
import { QueryCache, QueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

const DEFAULT_ERROR_MSG = 'Something went wrong.';

const queryClient = new QueryClient({
    queryCache: new QueryCache({
        onError: (error, query) => {
            let errorMessage = DEFAULT_ERROR_MSG;
            const serverError = get(error, 'message');
            const axiosError = get(error, 'message.response.data.message');
            if (serverError?.length > 0) {
                errorMessage = serverError;
            } else if (axiosError?.length > 0) {
                errorMessage = (error as AxiosError)?.response?.data?.message;
            }

            if (query.meta?.globalErrorNoop !== true) {
                message.error(errorMessage);
            }
        },
    }),
    defaultOptions: {
        queries: {
            suspense: true,
        },
    },
});

export { queryClient };
