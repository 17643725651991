import { createPipe, MaskedOptions, MaskedRangeOptions } from 'imask';
import { maskedTypes, unmaskedTypes } from 'masks/factory';

const currencyMaskOptions = (padded: boolean = true) => ({
    mask: [
        {
            mask: Number,
            scale: 2,
            thousandsSeparator: ',',
            padFractionalZeros: padded,
            normalizeZeros: true,
            radix: '.',
        },
        { mask: '' },
    ],
});
const unmaskCurrency = createPipe(currencyMaskOptions(), ...unmaskedTypes);
const maskCurrency = createPipe(currencyMaskOptions(), ...maskedTypes);
const maskCurrencyUnpadded = createPipe(currencyMaskOptions(false), ...maskedTypes);

const wholeNumberMaskOptions = {
    mask: [
        {
            mask: Number,
            scale: 0,
            thousandsSeparator: ',',
            padFractionalZeros: false,
            normalizeZeros: true,
        },
        {
            mask: '',
        },
    ],
};
const unmaskWholeNumber = createPipe(wholeNumberMaskOptions, ...unmaskedTypes);
const maskWholeNumber = createPipe(wholeNumberMaskOptions, ...maskedTypes);

const sixDigitCodeOptions: MaskedRangeOptions = {
    from: 0,
    to: 999999,
    autofix: true,
};

const einOptions: MaskedOptions = {
    mask: '00-0000000',
};

export {
    currencyMaskOptions,
    unmaskCurrency,
    maskCurrency,
    maskCurrencyUnpadded,
    //
    wholeNumberMaskOptions,
    unmaskWholeNumber,
    maskWholeNumber,
    //
    sixDigitCodeOptions,
    //
    einOptions,
};
