import React, { ComponentPropsWithoutRef, ForwardedRef, useEffect, useState } from 'react';
import { StyledButton } from 'components/button2/styled';
import { Colorable } from 'components/util';
import { ButtonContext, useButton } from './ButtonContext';

interface ButtonProps extends Omit<ComponentPropsWithoutRef<'button'>, 'color'>, Colorable {
    variant?: 'primary' | 'secondary' | 'tertiary';
    size?: 'sm' | 'md' | 'lg';
}

const Button = React.forwardRef(
    (
        {
            variant = 'primary',
            size = 'md',
            color = 'neutral',
            type = 'button',
            ...rest
        }: ButtonProps,
        ref: ForwardedRef<HTMLButtonElement>
    ) => {
        const [hasOrphanIcon, setHasOrphanIcon] = useState(false);
        return (
            <ButtonContext.Provider value={{ hasOrphanIcon, setHasOrphanIcon }}>
                <StyledButton
                    ref={ref}
                    variant={variant}
                    color={color}
                    size={size}
                    type={type}
                    {...rest}
                />
            </ButtonContext.Provider>
        );
    }
);

const ButtonOrphanIcon = ({ children }: ComponentPropsWithoutRef<'div'>) => {
    const { setHasOrphanIcon } = useButton();
    useEffect(() => {
        setHasOrphanIcon(true);
    }, [setHasOrphanIcon]);
    return <>{children}</>;
};

const Root = Button;
const OrphanIcon = ButtonOrphanIcon;

export {
    Button,
    ButtonOrphanIcon,
    //
    Root,
    OrphanIcon,
};
export type { ButtonProps };
