import { useContext } from 'react';
import { AppContext } from 'types/app';
import Context from 'assets/js/Context';

const useCurrentUser = () => {
    const {
        currentUser: {
            id,
            uid,
            phone,
            first_name: firstName,
            last_name: lastName,
            email,
            launchdarkly_hash: ldHash,
        },
    }: AppContext = useContext(Context);

    return {
        id,
        uid,
        firstName,
        lastName,
        email,
        phone,
        ldHash,
    };
};

export { useCurrentUser };
