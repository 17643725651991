import { MaskedDateOptions, MaskedNumberOptions, MaskedOptions } from 'imask';
import {
    currencyMaskOptions,
    einOptions,
    sixDigitCodeOptions,
    wholeNumberMaskOptions,
} from 'masks/numbers';
import { dateMaskOptions } from 'masks/dates';
import React, {
    ComponentPropsWithoutRef,
    ForwardedRef,
    ReactNode,
    forwardRef,
    useState,
    useRef,
    useEffect,
} from 'react';
import styled, { css } from 'styled-components';
import { CurrencySymbol } from 'customHooks/useCurrencySymbol';
import { InputFocusBorder, InputLikeStyles, SizeableStyles } from 'components/util';
import { FormLike, Sizeable } from 'components/util/types';
import { useIMask } from 'react-imask';

type CustomMaskOptions = MaskedDateOptions | MaskedNumberOptions;
interface InputProps
    extends Omit<ComponentPropsWithoutRef<'input'>, 'error'>,
        FormLike,
        Sizeable,
        MaskProps {
    leftAdornment?: ReactNode;
    rightAdornment?: ReactNode;
    customMask?: CustomMaskOptions;
    alreadyMasked?: boolean;
    isFocused?: boolean;
    showCurrencySymbol?: boolean;
}

type MaskType = 'currency' | 'decimal' | 'whole-number' | 'verification-code' | 'ein' | 'date';

type MaskProps = {
    mask?: MaskType;
};

const StyledInputContainer = styled.div<
    {
        leftAdornmentRef?: HTMLDivElement | null;
        rightAdornmentRef?: HTMLDivElement | null;
        isFocused?: boolean;
        disabled?: boolean;
    } & Sizeable &
        FormLike
>`
    ${InputLikeStyles};
    ${SizeableStyles};
    cursor: text;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    column-gap: 0.5rem;

    & input {
        width: 100%;
        border: none;
        box-shadow: none;
        outline: none;

        &[disabled=''] {
            pointer-events: none;
            background-color: #fafafa;
        }
    }


    ${({ isFocused }) =>
        isFocused &&
        css`
            ${InputFocusBorder};
        `}
}`;

const Input = forwardRef((props: InputProps, ref: ForwardedRef<HTMLInputElement>) => {
    const {
        leftAdornment: leftAdornmentConsumer,
        rightAdornment,
        className,
        inputSize,
        error,
        mask: maskType,
        onFocus: onFocusConsumer,
        onBlur: onBlurConsumer,
        onClick: onClickConsumer,
        customMask,
        disabled,
        alreadyMasked = false,
        isFocused: isFocusedConsumer,
        showCurrencySymbol = false,
        ...restProps
    } = props;
    const [isFocused, setIsFocused] = useState(isFocusedConsumer);
    const leftAdornment =
        maskType === 'currency' || showCurrencySymbol ? <CurrencySymbol /> : leftAdornmentConsumer;
    const maskedOpts = getComputedMaskOptions(customMask, maskType);
    const { ref: iMaskRef } = useIMask(maskedOpts);
    const internalRef = useRef<HTMLInputElement>(null);
    const mergedRef = mergeRefs([internalRef, ref, ...(alreadyMasked ? [] : [iMaskRef])]);

    useEffect(() => {
        setIsFocused(isFocusedConsumer);
        if (isFocusedConsumer) {
            internalRef?.current?.focus();
        }
    }, [isFocusedConsumer]);

    const onFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        onFocusConsumer?.(e);
        if (!e.isDefaultPrevented()) {
            setIsFocused(true);
        }
    };

    const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        onBlurConsumer?.(e);
        if (!e.isDefaultPrevented()) {
            setIsFocused(false);
        }
    };

    const onClick = (e: React.MouseEvent<HTMLInputElement>) => {
        if (disabled) {
            return e.preventDefault();
        }

        onClickConsumer && onClickConsumer(e);
        if (!e.isDefaultPrevented()) {
            internalRef.current?.focus();
        }
    };

    return (
        <StyledInputContainer
            className={className}
            inputSize={inputSize}
            isFocused={isFocused}
            error={error}
            disabled={disabled}
            onClick={onClick}
        >
            {leftAdornment && <div>{leftAdornment}</div>}
            <input
                ref={mergedRef}
                onBlur={onBlur}
                onFocus={onFocus}
                disabled={disabled}
                {...restProps}
            />
            {rightAdornment && <div>{rightAdornment}</div>}
        </StyledInputContainer>
    );
});

const getComputedMaskOptions = (customMask?: MaskedOptions<any>, mask?: MaskType) =>
    customMask ? customMask : mask ? getMaskOptions(mask) : ({} as MaskedOptions<any>);

const getMaskOptions = (maskType: MaskType): MaskedOptions<any> => {
    switch (maskType) {
        case 'whole-number':
            return wholeNumberMaskOptions;
        case 'currency':
            return currencyMaskOptions();
        case 'decimal':
            return currencyMaskOptions(false);
        case 'verification-code':
            return sixDigitCodeOptions;
        case 'ein':
            return einOptions;
        case 'date':
            return dateMaskOptions;
        default:
            return {} as MaskedOptions<any>;
    }
};

const mergeRefs =
    <T extends any = any>(
        refs: Array<React.MutableRefObject<T> | React.LegacyRef<T>>
    ): React.RefCallback<T> =>
    value => {
        refs.forEach(ref => {
            if (typeof ref === 'function') {
                ref(value);
            } else if (ref != null) {
                (ref as React.MutableRefObject<T | null>).current = value;
            }
        });
    };

export { Input, StyledInputContainer, getMaskOptions, getComputedMaskOptions, mergeRefs };
export type { InputProps };
