import styled, { keyframes } from 'styled-components';
import { hexToRgbA } from 'helpers/colors';

const circle = keyframes`
  0% {
    stroke-dashoffset: 312;
    transform: rotate(70deg);
  }
  60% {
    stroke-dashoffset: -312;
  }
  100% {
    stroke-dashoffset: -312;
    transform: rotate(450deg);
  }
`;

export const Loader = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    background: ${({ theme }) => hexToRgbA(theme.colors.background.project, 0.5)};
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const CircleSVG = styled.svg`
    width: 50px;
    height: 50px;
    transform: rotate(-90deg);
    stroke-linecap: round;
    stroke-width: 4;
    fill: none;
`;

export const Circle = styled.circle`
    stroke: ${({ theme }) => theme.colors.cobaltDark};
    stroke-dashoffset: 0;
    transform-origin: center;
    stroke-dasharray: 312;
    animation: ${circle} 1s linear infinite;
    opacity: 0.9;
`;
