import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact, {
    BugsnagErrorBoundary as IBugsnagErrorBoundary,
} from '@bugsnag/plugin-react';
import React, { ReactNode } from 'react';
import { ErrorSplash } from 'assets/js/pages/ErrorSplash';

interface ErrorBoundaryProps {
    children: ReactNode;
}

const UNAUTHORIZED = 401;
const statusCodeFactory = (codes: number[]) => codes.map(code => `status code ${code.toString()}`);

const IGNORE_EXCEPTIONS = [
    'ResizeObserver',
    'A network error occurred',
    ...statusCodeFactory([UNAUTHORIZED]),
];

let BugSnagErrorBoundary: IBugsnagErrorBoundary | undefined | null = null;

if (process.env.REACT_APP_BUGSNAG_API_KEY) {
    Bugsnag.start({
        onError: e => {
            e.errors.forEach(error => {
                if (IGNORE_EXCEPTIONS.some(exception => error.errorMessage.includes(exception))) {
                    error.errorClass = 'Ignored';
                }
            });

            e.addMetadata('config_data', {
                error_config_data: e.originalError?.config?.data,
                response: e.originalError?.response,
                request: e.originalError?.request,
            });
        },
        plugins: [new BugsnagPluginReact()],
        apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
    });

    BugSnagErrorBoundary = Bugsnag.getPlugin('react')?.createErrorBoundary(React);
}

const ErrorBoundary = (props: ErrorBoundaryProps) => {
    const { children } = props;

    if (process.env.REACT_APP_BUGSNAG_API_KEY && !!BugSnagErrorBoundary) {
        return (
            <BugSnagErrorBoundary FallbackComponent={ErrorSplash}>{children}</BugSnagErrorBoundary>
        );
    }

    return <>{children}</>;
};

export { ErrorBoundary };
