import styled, { createGlobalStyle, keyframes } from 'styled-components';

const overlayShow = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const GlobalStyle = createGlobalStyle`
    body {
        pointer-events: auto !important;
        overflow: hidden !important;
        position: relative !important;
        padding-left: 0;
        padding-top: 0;
        padding-right: 0;
        margin-left: 0;
        margin-top: 0;
        margin-right: 0 !important;
    }
`;

const Overlay = styled.div`
    z-index: ${props => props.theme.zIndex.PentOutermost};
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    inset: 0;
    @media (prefers-reduced-motion: no-preference) {
        animation: ${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1);
    }
`;

export { GlobalStyle, Overlay };
