import { CheckboxProps } from '@radix-ui/react-checkbox';
import React from 'react';
import { UseControllerProps, useController, FieldPath, FieldValues } from 'react-hook-form';
import { Checkbox, CheckboxInputProps } from 'components/checkbox/Checkbox';
import { useFormError } from 'components/form/UseFormError';

interface UseFormCheckboxInputProps<T extends FieldValues, K extends FieldPath<T>>
    extends UseControllerProps<T, K>,
        Omit<CheckboxInputProps, 'defaultValue' | 'name'> {}

const UseFormCheckboxInput = <T extends FieldValues, K extends FieldPath<T>>(
    props: UseFormCheckboxInputProps<T, K>
) => {
    const {
        control,
        name,
        onCheckedChange: onCheckedChangeConsumer,
        defaultValue,
        rules,
        ...restCheckboxProps
    } = props;

    const {
        field: { onChange: onChangeInternal, value, ...restFormProps },
    } = useController({
        name,
        control,
        defaultValue,
        rules,
    });
    const error = useFormError(name as FieldPath<T>);

    const onChecked: CheckboxProps['onCheckedChange'] = isChecked => {
        onChangeInternal(isChecked);

        onCheckedChangeConsumer && onCheckedChangeConsumer(isChecked);
    };

    return (
        <Checkbox
            {...restCheckboxProps}
            {...restFormProps}
            id={name}
            checked={value as CheckboxProps['checked']}
            onCheckedChange={onChecked}
            error={!!error?.message}
        />
    );
};

export { UseFormCheckboxInput };
export type { UseFormCheckboxInputProps };
