import React, { JSXElementConstructor, PropsWithChildren } from 'react';

const componentOrFragmentFactory = <T extends PropsWithChildren<unknown>>(
    Component: JSXElementConstructor<T>,
    cond: boolean
) => {
    return cond && !!Component ? Component : SanitizableFragment;
};

const SanitizableFragment = <T extends PropsWithChildren<unknown>>(props: T) => {
    return <>{props.children}</>;
};

export { componentOrFragmentFactory };
