import { css } from 'styled-components';

const FocusVisible = css`
    &::before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        border: 1px ${props => props.theme.color.onyx} dashed;
    }
`;

const FocusVisibleBefore = css`
    &:focus-visible {
        ${FocusVisible};
    }
`;

export { FocusVisible, FocusVisibleBefore };
