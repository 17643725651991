import styled, { StyledProps, css } from 'styled-components';
import { noSelect } from 'constants/styles';
import { InputProps } from 'components/input';
import { getLabelTextStyles } from 'components/input/util';
import Text from 'components/text';

export const getColors = ({ theme, error }: StyledProps<InputProps>) => {
    if (error) {
        return css`
            border: 1px solid ${theme.colors.input.border.error};
            background: ${theme.colors.input.background.error};
        `;
    }
    return css`
        border: 1px solid ${theme.colors.input.border.default};
        background: ${theme.colors.input.background.default};
    `;
};

const getInputStyles = ({ theme, disabled }: StyledProps<InputProps>) => css`
    height: ${theme.sizes.spacers.xxxl};
    border-radius: ${theme.radius.default};
    padding: 0 ${theme.sizes.spacers.md};
    color: ${disabled ? theme.colors.input.placeholder : theme.colors.input.color};
    pointer-events: ${disabled ? 'none' : 'inherit'};

    &::placeholder,
    ::-webkit-input-placeholder {
        color: ${theme.colors.input.placeholder};
    }

    :focus {
        border-color: ${theme.colors.input.border.focus};
        box-shadow: ${theme.shadows.input};
    }
`;

export const Input = styled.input`
    outline: none;
    font-size: ${p => p.theme.typography.body.size};
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    box-sizing: border-box;
    max-width: 100%;
    ${getInputStyles};
    ${getColors};
`;

export const TextArea = styled.textarea`
    outline: none;
    font-size: ${p => p.theme.typography.body.size};
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    box-sizing: border-box;
    max-width: 100%;
    ${getInputStyles};
    ${getColors};
`;

export const InputWrapper = styled.label`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
`;

const getLabelStyles = ({ theme, inline }: StyledProps<InputProps>) => css`
    margin-bottom: ${inline ? 0 : theme.sizes.spacers.xxs};
    flex-direction: ${inline ? 'row' : 'column'};
`;

export const Label = styled.div`
    ${noSelect};
    display: flex;
    flex-grow: 1;
    ${getLabelStyles};
`;

export const LabelText = styled(Text)`
    display: flex;
    align-items: center;
    ${getLabelTextStyles};
`;
