import { UseControllerProps, FieldPath, useController, FieldValues } from 'react-hook-form';
import {
    getComputedMaskOptions,
    Input as InputPrimitive,
    InputProps,
    mergeRefs,
} from 'components/input-v2/Input';
import { useFormError } from 'components/form/UseFormError';
import { useIMask } from 'react-imask';
import { MutableRefObject } from 'react';

interface UseFormTextInputProps<T extends FieldValues, K extends FieldPath<T>>
    extends UseControllerProps<T, K>,
        Omit<InputProps, 'defaultValue' | 'name'> {
    inputRef?: MutableRefObject<HTMLInputElement | null>;
}

const UseFormTextInput = <T extends FieldValues, K extends FieldPath<T>>(
    props: UseFormTextInputProps<T, K>
) => {
    const {
        name,
        rules,
        control,
        mask,
        customMask,
        defaultValue,
        inputRef,
        onChange: onChangeConsumer,
        ...restInputProps
    } = props;
    const error = useFormError(name as FieldPath<T>);

    const {
        field: { onChange: onChangeInternal, value, ref: formRef, ...restFormProps },
    } = useController({
        name,
        control,
        defaultValue,
        rules,
    });

    const maskedOpts = getComputedMaskOptions(customMask, mask);
    const { ref: iMaskRef } = useIMask(maskedOpts, {
        onAccept: (value, _maskRef, e) => {
            onChangeInternal(value);
        },
    });

    const isMasked = Object.keys(maskedOpts).length > 0;
    const ref = mergeRefs([
        formRef,
        ...(inputRef ? [inputRef] : []),
        ...(isMasked ? [iMaskRef] : []),
    ]);

    return (
        <InputPrimitive
            ref={ref}
            value={value}
            alreadyMasked={true}
            mask={mask}
            customMask={customMask}
            error={error?.message}
            onChange={e => {
                if (!isMasked) {
                    onChangeInternal(e.target.value);
                }
                onChangeConsumer?.(e);
            }}
            {...restFormProps}
            {...restInputProps}
        />
    );
};

export { UseFormTextInput };
export type { UseFormTextInputProps };
