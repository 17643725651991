export const setToLS = (key: string, value: any) => {
    window.localStorage.setItem(key, JSON.stringify(value));
};

export const getFromLS = (key: string) => {
    const value = window.localStorage.getItem(key);
    if (
        value &&
        /^[\],:{}\s]*$/.test(
            value
                .replace(/\\["\\/bfnrtu]/g, '@')
                .replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?/g, ']')
                .replace(/(?:^|:|,)(?:\s*\[)+/g, '')
        )
    ) {
        return JSON.parse(value);
    } else {
        return {};
    }
};
