import React, { ReactNode, useCallback, useState } from 'react';
import { StateContext as IStateContext } from 'customHooks/stateContext/context';
import { getFromLS, setToLS } from 'helpers/local-storage';
import Context, { initialState } from './context';

const LS_NAME = 'project_state';

function isPrimitive<T>(test: T) {
    return test !== Object(test);
}

const hasDiff = (first: { [key: string]: any }, second: { [key: string]: any }): boolean => {
    const firstKeys = Object.keys(first);
    const secondKeys = Object.keys(second);
    if (firstKeys.length !== secondKeys.length) return true;
    const result = firstKeys.find(item => {
        if (!second.hasOwnProperty(item)) return true;
        if (isPrimitive(second[item]) && isPrimitive(first[item])) return false;
        if (Array.isArray(first[item]) && Array.isArray(second[item])) return false;
        return hasDiff(first[item], second[item]);
    });
    return !!result;
};

const Provider = ({ children }: { children: ReactNode }) => {
    let localStorageState = getFromLS(LS_NAME);
    if (!localStorageState || hasDiff(initialState, localStorageState)) {
        localStorageState = initialState;
    }
    const [state, setState] = useState<any>(localStorageState);
    const [currentPage, setCurrentPage] = useState<keyof IStateContext['state'] | null>(null);

    const updateSearch = useCallback((page, value) => {
        setState((prevState: { [x: string]: any }) => {
            const newState = {
                ...prevState,
                [page]: {
                    ...prevState[page],
                    search: value,
                },
            };
            setToLS(LS_NAME, newState);
            return newState;
        });
    }, []);

    const updateFilters = useCallback((page, value) => {
        setState((prevState: { [x: string]: { filters: any } }) => {
            const newState = {
                ...prevState,
                [page]: {
                    ...prevState[page],
                    filters: {
                        ...prevState[page].filters,
                        ...value,
                    },
                },
            };
            setToLS(LS_NAME, newState);
            return newState;
        });
    }, []);

    return (
        <Context.Provider
            value={{
                state,
                currentPage,
                setCurrentPage,
                updateSearch,
                updateFilters,
            }}
        >
            {children}
        </Context.Provider>
    );
};

export default Provider;
