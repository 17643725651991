import { useEffect } from 'react';
import { LabelProps } from '@radix-ui/react-label';
import { Label } from 'components/label';
import { FieldPath, FieldValues } from 'react-hook-form';

interface UseFormLabelProps<T extends FieldValues, K extends FieldPath<T>> extends LabelProps {
    name: K;
}

const UseFormLabel = <T extends FieldValues, K extends FieldPath<T>>(
    props: UseFormLabelProps<T, K>
) => {
    const { name, ...rest } = props;

    useEffect(() => {
        const ele = document.querySelector(`[name="${name}"]`);
        if (ele && !ele.hasAttribute('aria-hidden')) {
            ele.id = ele.id.length === 0 ? name : ele.id;
        }
    }, [name]);

    return <Label htmlFor={name} {...rest} />;
};

export { UseFormLabel };
