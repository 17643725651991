import React, { ComponentPropsWithoutRef } from 'react';

interface InlineErrorProps extends ComponentPropsWithoutRef<'p'> {
    message?: string;
}

const InlineError = (props: InlineErrorProps) => {
    const { message, className = '', ...rest } = props;

    if (!message) {
        return null;
    }

    return (
        <p className={`text-sm font-semibold text-red-500 ${className}`} {...rest}>
            {message}
        </p>
    );
};

export { InlineError };
export type { InlineErrorProps };
