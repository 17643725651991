import React, { ReactNode } from 'react';

interface RenderOrNullProps {
    cond: boolean;
    children?: ReactNode;
}

/**
 * @deprecated
 *
 * This component is deprecated because we found that code within `children` would still execute regardless of the `cond` prop.
 *
 * For example:
 * ```
 * <RenderOrNull cond={false}>
 *  {console.log("this still gets executed")}
 * </RenderOrNull>
 * ```
 * Instead, use JS coalescing:
 * ```
 * {false && <>{console.log("this does not get executed")}</>}
 * ```
 */
const RenderOrNull = (props: RenderOrNullProps) => {
    const { cond, children } = props;

    if (cond) {
        return <>{children}</>;
    }

    return null;
};

export { RenderOrNull };
export type { RenderOrNullProps };
