import { FieldPath, useForm, UseFormReturn, UseFormProps, FieldValues } from 'react-hook-form';

interface UseGivebutterForm<TFieldValues extends FieldValues = FieldValues, TContext = any> {
    (props?: UseFormProps<TFieldValues, TContext>): UseFormReturn<TFieldValues, TContext> & {
        path: <T extends TFieldValues, K extends FieldPath<T>>(path: K) => K;
        resetFormButton: () => void;
    };
}

type UseGivebutterFormReturn<
    TFieldValues extends FieldValues = FieldValues,
    TContext = any
> = ReturnType<UseGivebutterForm<TFieldValues, TContext>>;

const useGivebutterForm = <TFieldValues extends FieldValues = FieldValues, TContext = any>(
    props?: UseFormProps<TFieldValues, TContext>
) => {
    const methods = useForm<TFieldValues>(props);
    const path = <T extends TFieldValues, K extends FieldPath<T>>(path: K) => path;
    const resetFormButton = () =>
        methods.reset(undefined, {
            keepErrors: true,
            keepDirty: true,
            keepTouched: true,
            keepValues: true,
            keepIsSubmitted: true,
            keepSubmitCount: true,
            keepDefaultValues: true,
            keepIsValid: true,
            keepDirtyValues: true,
        });

    return {
        ...methods,
        path,
        resetFormButton,
    };
};

export { useGivebutterForm };
export type { UseGivebutterForm, UseGivebutterFormReturn };
