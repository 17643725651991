import React, { ForwardedRef, forwardRef } from 'react';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import {
    DialogContentProps,
    DialogCloseProps,
    DialogTitleProps,
    DialogDescriptionProps,
} from '@radix-ui/react-dialog';
import { Overlay as OverlayPrimitive } from 'components/overlay';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { twMerge } from 'tailwind-merge';

const Dialog = DialogPrimitive.Root;
const DialogTrigger = DialogPrimitive.Trigger;
const DialogPortal = DialogPrimitive.Portal;
const DialogCancel = DialogPrimitive.Close;
const DialogOverlay = OverlayPrimitive;

const DialogBody = forwardRef((props: DialogContentProps, ref: ForwardedRef<HTMLDivElement>) => {
    const { className = '', ...rest } = props;
    return <div ref={ref} className={twMerge('p-8 pt-0', className)} {...rest} />;
});

const DialogContent = forwardRef((props: DialogContentProps, ref: ForwardedRef<HTMLDivElement>) => {
    const { className = '', ...rest } = props;
    return (
        <DialogPrimitive.Content
            ref={ref}
            className={twMerge(
                'fixed left-[50%] top-[50%] z-pentOutermost max-h-[85vh] w-[90vw] max-w-[500px] -translate-x-1/2 -translate-y-1/2 animate-dialog animate-opacity overflow-y-auto rounded-xl bg-white shadow-sm focus:outline-none',
                className
            )}
            {...rest}
        />
    );
});

const DialogClose = forwardRef((props: DialogCloseProps, ref: ForwardedRef<HTMLButtonElement>) => {
    const { className = '', children, ...rest } = props;
    return (
        <DialogPrimitive.Close
            ref={ref}
            className={twMerge(
                'absolute right-4 top-4 inline-flex h-[25px] w-[25px] cursor-pointer items-center justify-center rounded-full text-gray-300 hover:text-gray-500',
                className
            )}
            {...rest}
        >
            {children ? children : <FontAwesomeIcon size={'xl'} icon={faXmark} />}
        </DialogPrimitive.Close>
    );
});

const DialogHeader = forwardRef((props: DialogContentProps, ref: ForwardedRef<HTMLDivElement>) => {
    const { className = '', ...rest } = props;
    return (
        <div
            ref={ref}
            className={twMerge(
                'flex flex-col items-center justify-center gap-[0.5rem] px-[2rem] py-[2.5rem]',
                className
            )}
            {...rest}
        />
    );
});

const DialogTitle = forwardRef((props: DialogTitleProps, ref: ForwardedRef<HTMLHeadingElement>) => {
    const { className = '', asChild = true, children, ...rest } = props;

    return (
        <DialogPrimitive.Title ref={ref} asChild={asChild} {...rest}>
            <div
                className={twMerge(
                    'm-0 inline-block p-0 text-3xl font-semibold text-gray-900',
                    className
                )}
            >
                {children}
            </div>
        </DialogPrimitive.Title>
    );
});

const DialogDescription = forwardRef(
    (props: DialogDescriptionProps, ref: ForwardedRef<HTMLHeadingElement>) => {
        const { className = '', asChild = true, children, ...rest } = props;

        return (
            <DialogPrimitive.Description ref={ref} asChild={asChild} {...rest}>
                <div
                    className={twMerge(
                        'm-0 inline-block p-0 text-base font-medium text-gray-600',
                        className
                    )}
                >
                    {children}
                </div>
            </DialogPrimitive.Description>
        );
    }
);

const Root = Dialog;
const Header = DialogHeader;
const Body = DialogBody;
const Content = DialogContent;
const Close = DialogClose;
const Trigger = DialogTrigger;
const Portal = DialogPortal;
const Overlay = DialogOverlay;
const Title = DialogTitle;
const Description = DialogDescription;
const Cancel = DialogPrimitive.Close;

export {
    Dialog,
    DialogBody,
    DialogClose,
    DialogContent,
    DialogHeader,
    DialogTrigger,
    DialogPortal,
    DialogOverlay,
    DialogCancel,
    //
    Root,
    Body,
    Header,
    Content,
    Trigger,
    Close,
    Portal,
    Overlay,
    Title,
    Description,
    Cancel,
};
