import { useContext, useState } from 'react';
import { AppContext } from 'types/app';
import Context from 'assets/js/Context';
import { AccountSetting } from 'types/domain';

const useCurrentAccount = () => {
    const {
        currentAccount: {
            postal_address,
            phone,
            email,
            id: accountId,
            aid: accountAid,
            ein,
            is_verified: isVerified,
            timezone,
            type,
            campaigns,
            reader_purchase_url: readerUrl,
            legal_entity,
            settings,
            title,
            bcc_address,
            forward_address,
            contact_issue_count,
            has_integrations: hasIntegrations,
            custom_fields,
            referral_url: referralLink,
            is_beta: isBeta,
        },
        currentUser: { phone_verified_at, email_verified_at },
    }: AppContext = useContext(Context);
    const isEinVerified = !!ein || isVerified;
    const isPhoneVerified = !!phone_verified_at;
    const isEmailVerified = !!email_verified_at;
    const isCompletelyVerified = isPhoneVerified && isEmailVerified;
    const foundPaymentsCode = settings.find(
        item => item.code === window.Enums.Settings.EnableRecurringPayments.Code
    );
    const [recurringPaymentsSetting, setRecurringPaymentsSetting] = useState<
        AccountSetting | undefined
    >(foundPaymentsCode);
    const isRecurringPaymentsEnabled = !!recurringPaymentsSetting?.active;

    return {
        accountId,
        accountAid,
        postalAddress: postal_address,
        email,
        ein,
        phone,
        isPhoneVerified,
        isEmailVerified,
        isEinVerified,
        isCompletelyVerified,
        isBeta,
        timezone,
        type,
        campaigns,
        readerUrl,
        legalEntity: legal_entity,
        settings,
        title,
        isRecurringPaymentsEnabled,
        recurringPaymentsSetting,
        setRecurringPaymentsSetting,
        customFields: custom_fields,
        hasIntegrations,
        referralLink,
        emailConfig: {
            bccAddress: bcc_address,
            forwardAddress: forward_address,
        },
        warnings: {
            duplicatesCount: contact_issue_count.duplicates,
            hasDuplicates: contact_issue_count.duplicates > 0,
        },
    };
};

export { useCurrentAccount };
