import React from 'react';
import 'assets/css/NotificationHeader.css';
import Context from '../Context';

interface NotificationHeaderProps {
    backgroundColor?: string;
}
class NotificationHeader extends React.Component<NotificationHeaderProps> {
    render() {
        return (
            <div
                className="notification-header"
                style={{ background: this.props.backgroundColor || '#286ffa' }}
            >
                <div className="message">
                    <p className="message-title">{this.props.children}</p>
                </div>
            </div>
        );
    }
}

NotificationHeader.contextType = Context;

export default NotificationHeader;
